import * as React from 'react'

import { FormCheckbox, FormCheckboxProps } from 'semantic-ui-react'
import { ReduxFieldProps, SemanticFieldProps, mapErrorProps } from './SemanticField'
import { Field, WrappedFieldProps } from 'redux-form'

type RadioProps = Omit<FormCheckboxProps, 'onBlur' | 'onChange' | 'onFocus' | 'radio' | 'type'>

export type ReduxRadioProps = SemanticFieldProps<RadioProps & { value: string | boolean | number | undefined }>
export type InnerFieldProps = ReduxFieldProps<RadioProps>

const Component = (props: WrappedFieldProps & RadioProps) => {
  const { input, meta, label, value, ...rest } = props

  return (
    <FormCheckbox
      { ...input }
      type='radio'
      radio
      checked={ input.value === value }
      onChange={ (_, { value }) => input.onChange(value) }
      // Use the value that is passed to the Radio directly because if you use the onBlur event it has boolean values as string
      onBlur={ () => input.onBlur(value) }
      { ...mapErrorProps(meta, label) }
      value={ value } // Explicitely pass value because value doesn't get passed to the component by ReduxForm fields
      { ...rest }
    />
  )
}

export const ReduxRadio: React.FC<ReduxRadioProps> = ({ value, ...props }) => {
  return <Field component={ Component } props={ { value } } { ...props } />
}

ReduxRadio.displayName = 'ReduxRadio'

export default React.memo(ReduxRadio)
