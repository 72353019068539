import React from 'react'
import {Container} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {IRootReducerState} from '../../../../state/rootReducer'
import find from 'lodash/find'
import {RouteComponentProps} from 'react-router'
import {Redirect, withRouter} from 'react-router-dom'
import LogoExample from './LogoExample'
import PageTitle from '../../../layout/components/PageTitle'
import MultiColorLogoExample from './MultiColorLogoExample'
import ProjectDescription from '../ProjectDescription'
import ProjectsNavigation from '../ProjectsNavigation'
import BBXSEO from "../../../layout/components/BBXSEO";
import {useTranslation} from "react-i18next";

type Props =
  RouteComponentProps<{ itemName: string }>

const LogoAndBranding: React.FC<Props> = (props: any) => {
  const {t} = useTranslation()
  const {
    match: {
      params: {
        itemName
      }
    }
  } = props
  const logoAndBranding = useSelector<IRootReducerState, any>(state => state.portfolio.LogoAndBranding)
  const project = logoAndBranding ? find(logoAndBranding, {'NameUrl': itemName}) : []

  if (!project) {
    return <Redirect to='/404'/>
  } else {
    return (
      <>
        <Container>
          <BBXSEO
            titleTemplate={`${t(`portfolio.${project.Kind}`)} ${t('main.for')} ${project.Name} | ${t('main.portfolio')}`}
            description={t('seo.portfolioDescription')}
          />
          <PageTitle name={project.Name}/>

          <ProjectDescription project={project} type='logo-en-huisstijl'/>
          <LogoExample
            name={project.NameUrl}
            imageText={`${project.Name} - ${project.ImageText}`}
            type='logo-en-huisstijl'
          />
          {
            project.MultiColorLogo === '1' &&
            <MultiColorLogoExample
              name={project.NameUrl}
              imageText={`${project.Name} - ${project.ImageText}`}
              type='logo-en-huisstijl'
            />
          }
        </Container>
        <ProjectsNavigation
          items={logoAndBranding}
          type='logo-en-huisstijl'
        />
      </>
    )
  }
}

export default withRouter(LogoAndBranding)
