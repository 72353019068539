import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Item, Grid, Icon} from 'semantic-ui-react'
import PageTitle from '../../../layout/components/PageTitle'
import {Link} from 'react-router-dom'
import {servicesItems} from '../../../../constants/services'
import BBXSEO from "../../../layout/components/BBXSEO";

const Services: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <BBXSEO
        titleTemplate={`${t('main.overview')} | ${t('main.services')}`}
        description={t('seo.servicesOverviewDescription')}
      />
      <PageTitle name={t('main.services')}/>

      <p>
        {t('services.mainText')}<br/>
        {t('services.mainText21')}
        <Link to='/contact'>{t('services.mainText22')}</Link>
        {t('services.mainText23')}
      </p>
      <Grid columns={2} stackable>
        {
          servicesItems.map((item, key) => {
            return (
              <Grid.Column key={key} style={{marginTop: '30px'}}>
                <Item.Group>
                  <Link to={`/diensten/overzicht/${item.urlName}`}>
                    <Item className='services-item'>
                      <div className='services-icon-bg'>
                        <div className='services-icon-radial'>
                          <Icon className={`icon-${item.icon}`}/>
                        </div>
                      </div>
                      <Item.Content>
                        <Item.Header as='h2'>{t(`services.${item.name}`)}</Item.Header>
                        <Item.Description>
                          {t(`services.${item.name}Description`)}
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Link>
                </Item.Group>
              </Grid.Column>
            )
          })
        }
      </Grid>
    </Container>
  )
}

export default Services
