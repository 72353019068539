import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Grid, Form} from 'semantic-ui-react'
import Input from '../../../components/form/Input'
import TextArea from '../../../components/form/TextArea';
import Checkbox from '../../../components/form/Checkbox';
import Dropdown from '../../../components/form/Dropdown';
import Radio from '../../../components/form/Radio';
import {InjectedFormProps, reduxForm} from 'redux-form'
import {useSelector} from 'react-redux'
import {toastr} from 'react-redux-toastr'

type Props = InjectedFormProps

const QuotationForm: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {pristine, reset, submitting} = props
  // @ts-ignore
  const formValues = useSelector(state => state.form.quotationForm && state.form.quotationForm.values)

  const onSubmit = () => {
    // @ts-ignore
    window.emailjs.send(
      'gmail', 'template_offerte',
      {
        message_html: formValues.message,
        from_name: formValues.fullName,
        reply_to: formValues.emailAddress,
        phone_number: formValues.phoneNumber,
        website: formValues.website,
        company_name: formValues.companyName,
        budget: formValues.budget,
        alreadyBranding: formValues.alreadyBranding === 'yes' ? 'ja' : 'nee',
        logoDesign: formValues.logoDesign ? 'ja' : 'nee',
        logoRestyling: formValues.logoRestyling ? 'ja' : 'nee',
        brandingDesign: formValues.brandingDesign ? 'ja' : 'nee',
        brandingRestyling: formValues.brandingRestyling ? 'ja' : 'nee',
        printedMatter: formValues.printedMatter ? 'ja' : 'nee',
        websiteDesign: formValues.websiteDesign ? 'ja' : 'nee',
        fullWebsite: formValues.fullWebsite ? 'ja' : 'nee',
        illustration: formValues.illustration ? 'ja' : 'nee',
        infographic: formValues.infographic ? 'ja' : 'nee',
        lettering: formValues.lettering ? 'ja' : 'nee',
        advertisement: formValues.advertisement ? 'ja' : 'nee',
        brochuresLeaflets: formValues.brochuresLeaflets ? 'ja' : 'nee',
        other: formValues.other ? 'ja' : 'nee'
      }
    ).then(() => {
      toastr.success('', t('contact.emailSuccessfullySent'))
    })
  }

  const needOptions = [
    {name: 'logoDesign'},
    {name: 'logoRestyling'},
    {name: 'brandingDesign'},
    {name: 'brandingRestyling'},
    {name: 'printedMatter'},
    {name: 'websiteDesign'},
    {name: 'fullWebsite'},
    {name: 'illustration'},
    {name: 'infographic'},
    {name: 'lettering'},
    {name: 'advertisement'},
    {name: 'brochuresLeaflets'},
    {name: 'other'},
  ]

  const budgetOptions = [
    { key: '< 500', text: '< 500', value: '< 500' },
    { key: '500 - 1000', text: '500 - 1000', value: '500 - 1000' },
    { key: '1000 - 1500', text: '1000 - 1500', value: '1000 - 1500' },
    { key: '1500 - 2000', text: '1500 - 2000', value: '1500 - 2000' },
    { key: '2000 >', text: '2000 >', value: '2000 >' }
  ]

  let intViewportWidth = window.innerWidth
  const numberOfColumns =
    intViewportWidth > 1200
      ? 5
      : intViewportWidth > 768
      ? 4
      : 3

  return (
    <Form onSubmit={onSubmit} className='quotation-form'>

      <p><b>{t('quotation.alreadyBranding')}</b></p>
      <Grid stackable>
        <Grid.Column>
          <Radio
            label={t('quotation.yes')}
            name='alreadyBranding'
            value='yes'
          />
        </Grid.Column>
        <Grid.Column>
          <Radio
            label={t('quotation.no')}
            name='alreadyBranding'
            value='no'
          />
        </Grid.Column>
      </Grid>
      <br/>
      <p><b>{t('quotation.needs')}</b></p>
      <Grid stackable columns={numberOfColumns}>
        <Grid.Row>
          {
            needOptions.map((item, key) => {
              return (
                <Grid.Column key={key}>
                  <Checkbox
                    label={t(`quotation.${item.name}`)}
                    name={item.name}
                    type='checkbox'
                  />
                </Grid.Column>
              )
            })
          }
        </Grid.Row>
      </Grid>
      <br/>
      <br/>
      <Input
        required
        placeholder={t('contact.fullName')}
        name='fullName'
        type='text'
      />
      <Input
        placeholder={t('contact.companyName')}
        name='companyName'
        type='text'
      />
      <Input
        required
        placeholder={t('contact.emailAddress')}
        name='emailAddress'
        type='email'
      />
      <Input
        placeholder={t('contact.website')}
        name='website'
        type='text'
      />
      <Input
        placeholder={t('contact.phoneNumber')}
        name='phoneNumber'
        type='phone'
      />
      <Dropdown
        placeholder={t('quotation.budget')}
        name='budget'
        options={ budgetOptions }
      />
      <TextArea
        required
        placeholder={t('contact.message')}
        name='message'
        rows={12}
      />


      <Button type="submit" disabled={pristine || submitting}>{t('contact.send')}</Button>
      <Button type="button" disabled={pristine || submitting} onClick={reset}>{t('contact.reset')}</Button>
    </Form>
  )
}

export default reduxForm({
  form: 'quotationForm'
})(QuotationForm)
