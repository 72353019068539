import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../state/store'
import i18n from '../i18n'
import ReduxToastr from 'react-redux-toastr'

import SettingsPage from './Container'

const Application = () => {
  return (
    <React.Suspense fallback='loading'>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Router>
            <SettingsPage/>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position='bottom-right'
              transitionIn='bounceIn'
              transitionOut='bounceOut'
              progressBar
              closeOnToastrClick
            />
          </Router>
        </Provider>
      </I18nextProvider>
    </React.Suspense>
  )
}

export default Application
