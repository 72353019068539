import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Header, Image} from 'semantic-ui-react'
import {customers} from "../../../constants/customers"

const DashboardCustomers: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <Header as='h2'>{t('dashboard.customers')}</Header>
      <p>{t('dashboard.customersText')}</p>
      <br/>
      <div className='dashboard-customers-icons'>
        <Grid columns={6} stackable>
          {
            customers.map((item, key) => {
              return (
                <Grid.Column key={key}>
                  <div className='customer-image'>
                    <Image
                      className='customer-color-image'
                      alt={`${item.name} Logo`}
                      src={require(`../../../assets/images/customers/${item.nameUrl}-color.jpg`)}
                    />
                    <Image
                      className='customer-normal-image'
                      alt={`${item.name} Black & White Logo`}
                      src={require(`../../../assets/images/customers/${item.nameUrl}.jpg`)}
                    />
                  </div>
                </Grid.Column>
              )
            })
          }
        </Grid>
      </div>
    </>
  )
}

export default DashboardCustomers
