import React from 'react'
import {Container} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {IRootReducerState} from '../../../../state/rootReducer'
import find from 'lodash/find'
import {RouteComponentProps} from 'react-router'
import {Redirect, withRouter} from 'react-router-dom'
import PageTitle from '../../../layout/components/PageTitle'
import ProjectDescription from '../ProjectDescription'
import ProjectsNavigation from '../ProjectsNavigation'
import BBXSEO from "../../../layout/components/BBXSEO"
import {useTranslation} from "react-i18next"
import IllustrationExample from "./IllustrationExample"

type Props =
  RouteComponentProps<{ itemName: string }>

const Illustrations: React.FC<Props> = (props: any) => {
  const {t} = useTranslation()
  const {
    match: {
      params: {
        itemName
      }
    }
  } = props
  const illustrations = useSelector<IRootReducerState, any>(state => state.portfolio.Illustrations)
  const project = illustrations ? find(illustrations, {'NameUrl': itemName}) : []

  if (!project) {
    return <Redirect to='/404'/>
  } else {
    return (
      <>
        <Container>
          <BBXSEO
            titleTemplate={`${t(`portfolio.${project.Kind}`)} ${t('main.for')} ${project.Name} | ${t('main.portfolio')}`}
            description={t('seo.portfolioDescription')}
          />
          <PageTitle name={project.Name}/>

          <ProjectDescription project={project} type='illustraties'/>

          <IllustrationExample
            name={project.NameUrl}
            imageText={`${project.Name} - ${project.ImageText}`}
            type='illustraties'
            images={project.Images}
          />
        </Container>
        <ProjectsNavigation
          items={illustrations}
          type='illustraties'
        />
      </>
    )
  }
}

export default withRouter(Illustrations)
