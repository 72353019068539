import React from 'react'
import {useTranslation} from 'react-i18next'
import {Card, Container} from 'semantic-ui-react'
import PageTitle from "../layout/components/PageTitle";
import {portfolioCategories} from '../../constants/portfolio'
import BBXSEO from "../layout/components/BBXSEO";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../state/rootReducer";

const Dashboard: React.FC = () => {
  const {t} = useTranslation()

  let intViewportWidth = window.innerWidth
  const numberOfCards =
    intViewportWidth > 1200
      ? 3
      : intViewportWidth > 768
      ? 2
      : 1

  return (
    <Container>
      <BBXSEO
        titleTemplate={t('main.portfolio')}
        description={t('seo.portfolioDescription')}
      />
      <PageTitle name={t('main.portfolio')}/>
      <Card.Group itemsPerRow={numberOfCards} stackable>

        {portfolioCategories && portfolioCategories.map((item: any, key: number) => {
          const categoryItems =
            item.nameUrl === 'logo-en-huisstijl' ? useSelector<IRootReducerState, any>(state => state.portfolio.LogoAndBranding)
              : item.nameUrl === 'illustraties' ? useSelector<IRootReducerState, any>(state => state.portfolio.Illustrations)
              : item.nameUrl === 'kaarten' ? useSelector<IRootReducerState, any>(state => state.portfolio.Cards)
                : item.nameUrl === 'websites' ? useSelector<IRootReducerState, any>(state => state.portfolio.Websites)
                  : item.nameUrl === 'infographics' && useSelector<IRootReducerState, any>(state => state.portfolio.Infographics)

          return (
            <Card
              key={key}
              className='portfolio-thumbnail portfolio-thumbnail-category-view'
              href={`/portfolio/${item.nameUrl}`}
            >
              <Card.Content>
                <Card.Header>
                  <i className={`icon-${item.icon} portfolio-thumbnail-icon`} /><br/>
                  {t(`main.${item.name}`)}
                </Card.Header>
                <Card.Meta>
                  {categoryItems && categoryItems.length} items
                </Card.Meta>
              </Card.Content>
            </Card>
          )
        })}
      </Card.Group>
    </Container>
  )
}

export default Dashboard
