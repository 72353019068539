import * as React from 'react'

import { FormCheckbox, FormCheckboxProps } from 'semantic-ui-react'
import { SemanticFieldProps, mapErrorProps } from './SemanticField'
import { Field, WrappedFieldProps } from 'redux-form'
import { Omit } from '../../../utils/helpers'

export type StrippedCheckboxProps = Omit<FormCheckboxProps, 'onBlur' | 'onChange' | 'onFocus' | 'type'>

export type ReduxCheckboxProps = SemanticFieldProps<StrippedCheckboxProps>

const Component = (props: WrappedFieldProps & StrippedCheckboxProps) => {
  const {
    input: { value, onChange, ...restInput },
    meta,
    label,
    ...rest
  } = props

  return <FormCheckbox { ...restInput } type='checkbox' checked={ !!value } onChange={ (_, { checked }) => onChange(checked) } { ...mapErrorProps(meta, label) } { ...rest } />
}

export const ReduxCheckbox: React.FC<ReduxCheckboxProps> = props => <Field component={ Component } { ...props } />

ReduxCheckbox.displayName = 'ReduxCheckbox'

export default React.memo(ReduxCheckbox)
