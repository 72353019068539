import React from 'react'
import {useTranslation} from 'react-i18next'
import {NavLink, Link} from 'react-router-dom'
import styled from 'styled-components'

const menu = [
  {
    index: 0,
    name: 'home',
    url: '',
  },
  {
    index: 1,
    name: 'about',
    url: 'over',
  },
  {
    index: 2,
    name: 'portfolio',
    url: 'portfolio',
    subMenu: [
      {
        name: 'logoAndBranding',
        url: 'logo-en-huisstijl'
      },
      {
        name: 'illustrations',
        url: 'illustraties'
      },
      {
        name: 'cards',
        url: 'kaarten'
      },
      {
        name: 'websites',
        url: 'websites'
      }
      // {
      //   name: 'infographics',
      //   url: 'infographics'
      // }
    ]
  },
  {
    index: 3,
    name: 'services',
    url: 'diensten',
    subMenu: [
      {
        name: 'overview',
        url: 'overzicht'
      },
      // {
      //   name: 'process',
      //   url: 'werkwijze'
      // },
      {
        name: 'customers',
        url: 'klanten'
      }
    ]
  },
  {
    index: 4,
    name: 'quotation',
    url: 'offerte'
  },
  {
    index: 5,
    name: 'contact',
    url: 'contact',
  }
]

const HeaderMenu: React.FC = () => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState(false)

  const menuSubItems = (items: any, urlParent: any) => {
    return (
      <>
        <ul className='sub'>
          {
            items.map((item: any, key: number) => {
              const name = `${item.name}`
              const url = `/${urlParent}/${item.url}`
              return (
                <li key={key}>
                  <Link to={url}>
                    {t(`main.${name}`)}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </>
    )
  }

  const menuSubItemsMobile = (items: any, urlParent: any) => {
    return (
      <>
        <ul className='sub'>
          {
            items.map((item: any, key: number) => {
              const name = `${item.name}`
              const url = `/${urlParent}/${item.url}`
              return (
                <li key={key}>
                  <Link to={url} onClick={() => setOpen(!open)}>
                    {t(`main.${name}`)}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </>
    )
  }

  const menuItems = menu.map((item, key) => {
    const url = `/${item.url}`
    if (item.name === 'home') {
      return (
        <li key={key}>
          <NavLink exact to={url}>
            <h1 className='parent-menu-text'>{t(`main.${item.name}`)}</h1>
          </NavLink>
        </li>
      )
    } else {
      return (
        <li key={key}>
          <NavLink to={url}>
            <h1 className='parent-menu-text'>{t(`main.${item.name}`)}</h1>
          </NavLink>
          {item.subMenu && menuSubItems(item.subMenu, item.url)}
        </li>
      )
    }
  })

  const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFF;
  transform: ${({open}) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

  const Menu = ({open}) => {
    return (
      <StyledMenu open={open} className='styled-menu'>
        <ul className='parent-menu-mobile'>
          {
            menu.map((item, key) => {
              const url = `/${item.url}`
              return (
                <li key={key}>
                  <NavLink to={url} onClick={() => setOpen(!open)}>
                    <h1 className='parent-menu-text-mobile'>{t(`main.${item.name}`)}</h1>
                  </NavLink>
                  {item.subMenu && menuSubItemsMobile(item.subMenu, item.url)}
                </li>
              )
            })
          }
        </ul>
      </StyledMenu>
    )
  }

  const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: rgba(0,0,0,.87);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({open}) => open ? '0' : '1'};
      transform: ${({open}) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

  const Burger = ({open, setOpen}) => {
    return (
      <StyledBurger className='styled-burger' open={open} onClick={() => setOpen(!open)}>
        <div/>
        <div/>
        <div/>
      </StyledBurger>
    )
  }

  return (
    <nav id='menu'>
      <ul className='parent-menu'>
        {menuItems}
      </ul>
      <div>
        <Burger open={open} setOpen={setOpen}/>
        <Menu open={open} setOpen={setOpen}/>
      </div>
    </nav>
  )
}

export default HeaderMenu
