import * as React from 'react'
import { Store } from 'redux'
import { DispatchProp, connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'

interface IState<TResult> {
  isLoading: boolean
  result?: TResult
}

export interface IWithDataProps<TResult> {
  result?: TResult
  setResult: (arg: TResult) => void
  reload: () => Promise<void>
}

/**
 * A higher order component that prevents the rendering of the sub components
 * until the supplied function's promise is resolved.
 *
 * @param {function: Promise} dataLoader An async function or a function that returns a promise.
 * @param {function: Promise} shouldUpdate A function which hooks into componentWillReceiveProps. when true, triggers a reload of the date.
 */
const withData = <TProps, TResult>(
  dataLoader: (props: TProps, store: Pick<Store<any>, 'dispatch'>) => Promise<TResult>,
  shouldUpdate?: (oldProps: any, newProps: any) => boolean
) => {
  // TODO remove curly braces when prettier-eslint issue is fixed
  /**
   * @param {Component} WrappedComponent
   * @return {Component}
   */
  return (WrappedComponent: React.ComponentType<IWithDataProps<TResult>>) => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

    class WithData extends React.Component<TProps & DispatchProp, IState<TResult>> {
      static displayName = `WithData(${ displayName })`
      static WrappedComponent = WrappedComponent

      state: IState<TResult> = {
        isLoading: true,
        result: undefined
      }

      private _isMounted = false

      async loadData(passedProps: TProps) {
        try {
          const result = await dataLoader(passedProps, { dispatch: this.props.dispatch })

          if (this._isMounted) {
            this.setState({
              isLoading: false,
              result
            })
          }
        } catch (e) {
          console.error(e)
          if (this._isMounted) {
            this.setState({ isLoading: false })
          }
        }
      }

      async componentDidMount() {
        this._isMounted = true
        await this.loadData(this.props)
      }

      async componentWillReceiveProps(newProps: TProps) {
        if (shouldUpdate && shouldUpdate(this.props, newProps)) {
          this.setState({ isLoading: true })
          await this.loadData(newProps)
        }
      }

      async componentWillUnmount() {
        this._isMounted = false
      }

      reloadData = async () => {
        await this.loadData(this.props)
      }

      setResult = (data: any) => {
        this.setState({ result: data })
      }

      render() {
        const { isLoading, result } = this.state

        // TODO(Lorenzo): This causes a weird looking flicker when the api responds fast
        return isLoading ? <Loader active /> : <WrappedComponent { ...this.props } result={ result } setResult={ this.setResult } reload={ this.reloadData } />
      }
    }

    return connect()<any>(WithData)
  }
}

export default withData
