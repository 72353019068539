import React from 'react'
import times from 'lodash/times'

const HeaderRulerTop: React.FC = () => {
  const rulerItemLines = times(9, ((key) => {
    return <div key={key}/>
  }))

  const rulerItems = times(40, ((key) => {
    return (
      <div key={key}>
        <p>{key === 0 ? key : `${key}00`}</p>
        {rulerItemLines}
      </div>
    )
  }))

  return (
    <div id='header-ruler-top'>
      {rulerItems}
    </div>
  )
}

export default HeaderRulerTop
