import * as React from 'react'

import { Form, FormInputProps } from 'semantic-ui-react'
import { ReduxFieldProps, SemanticFieldProps, mapErrorProps } from './SemanticField'
import { Field, WrappedFieldProps } from 'redux-form'
import { Omit } from '../../../utils/helpers'

export type StrippedInputProps = Omit<FormInputProps, 'input'>

export type ReduxInputProps = SemanticFieldProps<StrippedInputProps>
type InnerFieldProps = ReduxFieldProps<StrippedInputProps>

const Component = (props: InnerFieldProps & WrappedFieldProps) => {
  const { input, meta, label, ...rest } = props

  return (
    <Form.Input
      label={ label }
      { ...input }
      { ...rest }
      { ...mapErrorProps(meta, label) }
    />
  )
}

export const ReduxInput: React.FC<ReduxInputProps> = props => <Field component={ Component } { ...props } />

export default React.memo(ReduxInput)
