import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Grid, Image} from 'semantic-ui-react'
import PageTitle from '../../../layout/components/PageTitle'
import {customers} from '../../../../constants/customers'
import BBXSEO from "../../../layout/components/BBXSEO";

const Customers: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <BBXSEO
        titleTemplate={`${t('main.customers')} | ${t('main.services')}`}
        description={t('seo.servicesCustomersDescription')}
      />
      <PageTitle name={t('main.customers')}/>

      <p>{t('dashboard.customersText')}</p>
      <Grid columns={6} stackable>
        {
          customers.map((item, key) => {
            return (
              <Grid.Column key={key}>
                <div className='customer-image'>
                  <Image
                    className='customer-color-image'
                    alt={`${item.name} Logo`}
                    src={require(`../../../../assets/images/customers/${item.nameUrl}-color.jpg`)}
                  />
                  <Image
                    className='customer-normal-image'
                    alt={`${item.name} Black & White Logo`}
                    src={require(`../../../../assets/images/customers/${item.nameUrl}.jpg`)}
                  />
                </div>
              </Grid.Column>
            )
          })
        }
      </Grid>
    </Container>
  )
}

export default Customers
