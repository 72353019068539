import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Header, Icon} from 'semantic-ui-react'
import {servicesItems} from "../../../constants/services";
import {Link} from "react-router-dom";

const DashboardServices: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <Header as='h2'>{t('footer.aboutHeader')}</Header>
      <p>
        {t('services.mainText')}<br/>
        {t('services.mainText21')}
        <Link to='/contact'>{t('services.mainText22')}</Link>
        {t('services.mainText23')}
      </p>
      <br/>
      <div className='dashboard-services-icons'>
        <Grid columns={4}>
          {
            servicesItems.map((item, key) => {
              return (
                <Grid.Column key={key}>
                  <Link
                    to={`/diensten/overzicht/${item.urlName}`}
                    className='dashboard-services-links'
                  >
                    <Icon className={`icon-${item.icon}`}/>
                    <p>{t(`services.${item.name}`)}</p>
                  </Link>
                </Grid.Column>
              )
            })
          }
        </Grid>
      </div>
    </>
  )
}

export default DashboardServices
