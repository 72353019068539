import { ActionTypes } from './types'

export const getPortfolioItems = () => ({
  type: ActionTypes.GET_PORTFOLIO_ITEMS,
  payload: {
    request: {
      method: 'GET',
      url: `portfolio`
    }
  }
})
