import React from 'react'
import {Container} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {IRootReducerState} from '../../../../state/rootReducer'
import find from 'lodash/find'
import {RouteComponentProps} from 'react-router'
import {Redirect, withRouter} from 'react-router-dom'
import PageTitle from '../../../layout/components/PageTitle'
import ProjectDescription from '../ProjectDescription'
import ProjectsNavigation from '../ProjectsNavigation'
import BBXSEO from "../../../layout/components/BBXSEO"
import {useTranslation} from "react-i18next"
import CardExample from "./CardExample"

type Props =
  RouteComponentProps<{ itemName: string }>

const Cards: React.FC<Props> = (props: any) => {
  const {t} = useTranslation()
  const {
    match: {
      params: {
        itemName
      }
    }
  } = props
  const cards = useSelector<IRootReducerState, any>(state => state.portfolio.Cards)
  const project = cards ? find(cards, {'NameUrl': itemName}) : []

  if (!project) {
    return <Redirect to='/404'/>
  } else {
    return (
      <>
        <Container>
          <BBXSEO
            titleTemplate={`${t(`portfolio.${project.Kind}`)} ${t('main.for')} ${project.Name} | ${t('main.portfolio')}`}
            description={t('seo.portfolioDescription')}
          />
          <PageTitle name={project.Name}/>

          <ProjectDescription project={project} type='kaarten'/>

          <CardExample
            name={project.NameUrl}
            type='kaarten'
            images={project.Images}
            imageText={`${project.Name} - ${project.ImageText}`}
          />
        </Container>
        <ProjectsNavigation
          items={cards}
          type='kaarten'
        />
      </>
    )
  }
}

export default withRouter(Cards)
