import * as React from 'react'

import { BaseFieldProps, WrappedFieldMetaProps } from 'redux-form'
import Label from './Label'
import { HtmlLabelProps, SemanticShorthandItem } from 'semantic-ui-react'

// @ts-ignore
type FilteredFieldProps<P> = BaseFieldProps

interface ISemanticReduxProps {
  label?: string | React.ReactNode
}

export type SemanticFieldProps<P> = FilteredFieldProps<P> & P & ISemanticReduxProps
export type ReduxFieldProps<P> = BaseFieldProps<P> & P & ISemanticReduxProps

export const mapErrorProps = (meta: WrappedFieldMetaProps, label: SemanticShorthandItem<HtmlLabelProps>) => {
  const mappedLabel = typeof label === 'string' ? <Label content={ label } { ...meta } /> : label
  const error = meta.touched && !!meta.error

  return {
    label: mappedLabel,
    error
  }
}
