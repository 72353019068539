import React from 'react'
import {compose} from 'redux'
import withData from '../../../utils/WithData'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Button, Icon, Grid, Modal, Segment, Popup} from 'semantic-ui-react'
import replace from 'lodash/replace'
import Cookies from 'universal-cookie'
import {colors} from '../../../constants/colors'
import BBXDivider from "./BBXDivider"

const loader = async () => {
  const initialHexValue = cookies.get('color_hex') === undefined ? '0D78D8' : cookies.get('color_hex')
  const initialRgbValue = cookies.get('color_rgb') === undefined ? '13, 120, 216' : cookies.get('color_rgb')

  cookies.get('color_hex') === undefined && cookies.set('color_hex', initialHexValue, {
    path: '/',
    maxAge: 60 * 60 * 24 * 365
  })
  cookies.get('color_rgb') === undefined && cookies.set('color_rgb', initialRgbValue, {
    path: '/',
    maxAge: 60 * 60 * 24 * 365
  })
}

const enhance = compose<any>(
  withData(loader),
  withTranslation()
)

type Props = WithTranslation

const cookies = new Cookies()

class LayoutColor extends React.Component<Props> {
  state = {
    hex: cookies.get('color_hex') === undefined ? '0D78D8' : cookies.get('color_hex'),
    rgb: cookies.get('color_rgb') === undefined ? '13, 120, 216' : cookies.get('color_rgb'),
    oldHex: cookies.get('color_hex'),
    oldRgb: cookies.get('color_rgb'),
    bbxHex: '0D78D8',
    bbxRgb: '13, 120, 216',
    showModal: false
  }

  onColorClick = (hex: string, rgb: string) => {
    const values = {
      hex: replace(hex, '#', ''),
      rgb: `${rgb}`
    }
    this.setState({
      hex: values.hex,
      rgb: values.rgb
    })
  }

  handleCancel = () => {
    const oldColors = {
      hex: this.state.oldHex,
      rgb: this.state.oldRgb
    }

    this.setState({
      hex: oldColors.hex,
      rgb: oldColors.rgb,
      showModal: false
    })
  }

  handleSave = () => {
    const currentColors = {
      hex: this.state.hex,
      rgb: this.state.rgb
    }

    cookies.set('color_hex', currentColors.hex, {path: '/', maxAge: 60 * 60 * 24 * 365})
    cookies.set('color_rgb', currentColors.rgb, {path: '/', maxAge: 60 * 60 * 24 * 365})
    this.setState({
      hex: currentColors.hex,
      rgb: currentColors.rgb,
      oldHex: currentColors.hex,
      oldRgb: currentColors.rgb,
      showModal: false
    })
  }

  openModal = () => {
    this.setState({
      showModal: true
    })
  }

  render() {
    const {t} = this.props
    const {hex} = this.state
    return (
      <>
        <style dangerouslySetInnerHTML={{
          __html: `
        h1, h2, h3, h4, h5, h6,
        .ui.header,
        ul.parent-menu li:hover a h1,
        ul.parent-menu li a.active h1,
        .ui.items>.item>.content>.header,
        .ui.modal>.header
        {
          color: #${hex};
        }

        .social-media-links:hover i,
        .about-social-media-links:hover i
        {
          color: #${hex}!important;
        }

        #logo .logo_letter,
        #services-logo-design-image polygon,
        #services-branding-design-image g:nth-of-type(1) rect,
        #services-branding-design-image g:nth-of-type(1) path
        {
          fill: #${hex}!important;
        }

        ul.sub {
          border-bottom: 2px solid #${hex};
        }

        .colored-theme-bar
        {
          background: #${hex};
        }
        #header
         {
          background-color: #${hex};
          background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
          background-position: -1px -1px, -1px -1px, -1px -1px, -1px -1px;
          background-image: -webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
          -webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),
          -webkit-linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
          -webkit-linear-gradient(0, rgba(255,255,255,.2) 1px, transparent 1px);
          background-image: -moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
          -moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px),
          -moz-linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
          -moz-linear-gradient(0, rgba(255,255,255,.2) 1px, transparent 1px);
          background-image: linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
          linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px),
          linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
          linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px);
          -pie-background: linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 100px,
          linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 100px,
          linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px) -1px -1px / 20px,
          linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px) -1px -1px / 20px,
          #${hex};
        }
        `
        }}/>
        <Modal
          open={this.state.showModal}
          closeOnDimmerClick={false}
          className='modal-colors'
          trigger={
            <div className='change-color' onClick={() => this.openModal()}>
              <Icon className='icon-droplet'/>
            </div>
          }
        >
          <Modal.Header as='h1'>{t('main.pickColor')}</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{t('main.pickColorText')}</p>
              <div className='header-settings-colors'>
                {colors && colors.map((item: any, key: number) => {
                  return (
                    <Popup
                      inverted
                      key={key}
                      trigger={
                        <div
                          style={{background: `#${item.hex}`, cursor: 'pointer'}}
                          className='color-item'
                          onClick={() => this.onColorClick(item.hex, item.rgb)}
                        />
                      }
                      content={`#${item.hex}`}
                      position='top center'
                    />
                  )
                })
                }
              </div>
              <BBXDivider/>
              <Grid columns='equal' className='color-examples'>
                <Grid.Column>
                  <p>{t('main.chosenColor')}</p>
                  <Popup
                    inverted
                    trigger={
                      <Segment
                        style={{background: `#${hex}`}}
                      />
                    }
                    content={`#${hex}`}
                    position='top center'
                  />
                </Grid.Column>
                <Grid.Column>
                  <p>{`${t('main.oldColor')} (${t('main.clickToReset')})`}</p>
                  <Popup
                    inverted
                    trigger={
                      <Segment
                        style={{background: `#${this.state.oldHex}`, cursor: 'pointer'}}
                        onClick={() => this.onColorClick(this.state.oldHex, this.state.oldRgb)}
                      />
                    }
                    content={`#${this.state.oldHex}`}
                    position='top center'
                  />
                </Grid.Column>
                <Grid.Column>
                  <p>{`${t('main.bbxBlue')} (${t('main.clickToReset')})`}</p>
                  <Popup
                    inverted
                    trigger={
                      <Segment
                        style={{background: `#${this.state.bbxHex}`, cursor: 'pointer'}}
                        onClick={() => this.onColorClick(this.state.bbxHex, this.state.bbxRgb)}
                      />
                    }
                    content={`#${this.state.bbxHex}`}
                    position='top center'
                  />
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button positive icon='checkmark' content={t('main.save')} onClick={this.handleSave}/>
            <Button negative icon='remove' content={t('main.cancel')} onClick={this.handleCancel}/>
          </Modal.Actions>
        </Modal>
      </>
    )
  }
}

export default enhance(LayoutColor)
