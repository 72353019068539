import React from 'react'
import {Container, Button, Icon} from 'semantic-ui-react'
import findIndex from 'lodash/findIndex'
import {RouteComponentProps} from 'react-router'
import {withRouter} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {servicesItems} from '../../../../../constants/services'

type Props =
  RouteComponentProps<{ category: string }>

const ServicesNavigation: React.FC<Props> = (props: any) => {
  const {
    match: {
      params: {
        category
      }
    }
  } = props

  const {t} = useTranslation()

  const index = findIndex(servicesItems, {'urlName': category})
  // @ts-ignore
  const previousIndex = parseInt(index, 10) - 1
  const previousItem = servicesItems[previousIndex]
  // @ts-ignore
  const nextIndex = parseInt(index, 10) + 1
  const nextItem = servicesItems[nextIndex]

  return (
    <div id='project-navigations'>
      <svg id='project-navigation-top' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'
           preserveAspectRatio='none'>
        <polygon points='0 100,100 100,100 0'/>
      </svg>
      <div id='project-navigation-middle'>
        <Container>
          <div id='buttons'>
            {
              previousItem &&
              <Button
                basic
                animated='vertical'
                href={`/diensten/overzicht/${previousItem.urlName}`}
              >
                <Button.Content visible>
                  {t(`services.${previousItem.name}`)}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name='chevron circle left'/>
                </Button.Content>
              </Button>
            }
            <Button
              basic
              size='big'
              animated='vertical'
              href={`/diensten/overzicht`}
            >
              <Button.Content visible>
                {t('services.allServices')}
              </Button.Content>
              <Button.Content hidden>
                <Icon name='list layout'/>
              </Button.Content>
            </Button>
            {
              nextItem &&
              <Button
                basic
                animated='vertical'
                href={`/diensten/overzicht/${nextItem.urlName}`}
              >
                <Button.Content visible>
                  {t(`services.${nextItem.name}`)}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name='chevron circle right'/>
                </Button.Content>
              </Button>
            }
          </div>
        </Container>
      </div>
      <svg id='project-navigation-bottom' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'
           preserveAspectRatio='none'>
        <polygon points='100 0,0 100,0 0'/>
      </svg>
    </div>
  )
}

export default withRouter(ServicesNavigation)
