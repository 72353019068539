export const portfolioCategories = [
  {
    name: 'logoAndBranding',
    storeName: 'LogoAndBranding',
    icon: 'logo-design',
    nameUrl: 'logo-en-huisstijl'
  },
  {
    name: 'illustrations',
    storeName: 'Illustrations',
    icon: 'illustration-design',
    nameUrl: 'illustraties'
  },
  {
    name: 'cards',
    storeName: 'Cards',
    icon: 'card-design',
    nameUrl: 'kaarten'
  },
  {
    name: 'websites',
    storeName: 'Websites',
    icon: 'websites',
    nameUrl: 'websites'
  }
  // {
  //   name: 'infographics',
  //   storeName: 'Infographics',
  //   nameUrl: 'infographics'
  // }
]

export const portfolioItems = {
  "LogoAndBranding": [
    {
      "Uuid": "8e78c7b4-c2e4-4c77-8414-d0dcad18f16c",
      "Name": "Maud van den Heuvel Photography",
      "NameUrl": "maud-van-den-heuvel-photography",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2020-06-24",
      "Kind": "Logo",
      "Customer": "Maud van den Heuvel Photography",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "1",
      "Display": "1"
    },
    {
      "Uuid": "06ed63c2-9514-11ea-bb37-0242ac130002",
      "Name": "Klim en Klauter",
      "NameUrl": "klim-en-klauter",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2020-05-01",
      "Kind": "LogoConcept",
      "Customer": "Klim en Klauter",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "c0d12096-9512-11ea-bb37-0242ac130002",
      "Name": "Project Wijsheid",
      "NameUrl": "project-wijsheid",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2020-05-01",
      "Kind": "LogoConcept",
      "Customer": "Project Wijsheid",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "ea2adde3-1cad-4a8d-bea9-2c3fb9477567",
      "Name": "Creative Store",
      "NameUrl": "creative-store",
      "ImageText": "Huisstijl Ontwerp",
      "Version": "1",
      "Date": "2020-04-30",
      "Kind": "Huisstijl",
      "Customer": "Creative Store",
      "Link": "https://www.creativestore.nl",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "1",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "1",
      "Display": "1"
    },
    {
      "Uuid": "75f8db1a-54b0-4328-b277-d6f96f34f556",
      "Name": "Parket Agentuur",
      "NameUrl": "parket-agentuur",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2019-04-03",
      "Kind": "LogoConcept",
      "Customer": "Parket Agentuur",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "37462204-cd4c-4fcf-b2ec-919d8ef3b852",
      "Name": "SFE",
      "NameUrl": "sfe",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2019-02-04",
      "Kind": "LogoConcept",
      "Customer": "SFE",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "4f9cd9e5-8533-47eb-a261-ef98cb5ae5ad",
      "Name": "PIM",
      "NameUrl": "pim",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2019-01-11",
      "Kind": "LogoConcept",
      "Customer": "PIM",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "9cc24081-1e85-49e5-9fe3-66a360e7163b",
      "Name": "BIM Innovation Group",
      "NameUrl": "bim-innovation-group",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2019-01-11",
      "Kind": "LogoConcept",
      "Customer": "BIM Innovation Group",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "2360f4ef-d36d-4f10-b365-be3e3a04a5fa",
      "Name": "30 Bis",
      "NameUrl": "30-bis",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2018-11-07",
      "Kind": "LogoConcept",
      "Customer": "30 Bis",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "bb1026c8-52b1-45f7-860f-db72e2b137e1",
      "Name": "Mundo Marine",
      "NameUrl": "mundo-marine",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2017-05-19",
      "Kind": "LogoConcept",
      "Customer": "Mundo Marine",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "afd67577-26d9-4c5a-850c-1d877a6f25b2",
      "Name": "BLKSM Management",
      "NameUrl": "blksm-management",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-11-27",
      "Kind": "LogoConcept",
      "Customer": "BLKSM Management",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "46112f77-6f9a-41fc-bc91-b4b7807d2ef8",
      "Name": "Joni Roelse",
      "NameUrl": "joni-roelse",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-08-28",
      "Kind": "LogoConcept",
      "Customer": "Joni Roelse",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "a30c34ae-2a43-446e-bcfe-a2f0255bdf5e",
      "Name": "MTS Vogelaar",
      "NameUrl": "mts-vogelaar",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-08-21",
      "Kind": "LogoConcept",
      "Customer": "MTS Vogelaar",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "8dedd624-01b7-47f3-9d7d-11ec5db4f0f3",
      "Name": "De Noordster",
      "NameUrl": "de-noordster",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-02-25",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "3aa971e3-f5e5-439a-92a0-3d8ae3dee757",
      "Name": "Loyalty Rockstars",
      "NameUrl": "loyalty-rockstars",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-01-09",
      "Kind": "LogoConcept",
      "Customer": "Loyalty Rockstars",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "4042f339-b7ef-4ea4-ab4c-ce6098dabbac",
      "Name": "BuPsyCon",
      "NameUrl": "bupsycon",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2015-01-09",
      "Kind": "LogoConcept",
      "Customer": "BuPsyCon",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "1946f91d-90ec-404b-8871-42f35d528b7c",
      "Name": "GDS Keramiek",
      "NameUrl": "gds-keramiek",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2014-02-16",
      "Kind": "LogoConcept",
      "Customer": "GDS Keramiek",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "d60cad7b-0bae-4bb8-87b0-8fb9d1a28d02",
      "Name": "Darwinpark\n",
      "NameUrl": "darwinpark",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2013-04-18",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "In the \"work cart 'you can do jobs when in the winter its too cold outside to get to work. From April to October the farmer coffeehouse is open, here you and your colleagues can sell items like coffee, tea, cookies, eggs, honey and homemade products. On a fine summer day the pony Rizan runs  circles with the pony cart. Most of the work you do outside. There many volunteers to help on the farm and many people visit the park.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "6642e066-60b7-4934-9641-6bc1792d4343",
      "Name": "Huisartsenpraktijk van Zuiden",
      "NameUrl": "huisartsenpraktijk-van-zuiden",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2013-01-26",
      "Kind": "LogoConcept",
      "Customer": "Huisartsenpraktijk van Zuiden",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "1b16dc2d-445e-4fcb-bf56-a2bb1754578f",
      "Name": "Plug-in Housing",
      "NameUrl": "plug-in-housing",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2012-09-15",
      "Kind": "LogoConcept",
      "Customer": "Plug-in Housing",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "70e06d99-5535-4e87-a01d-d52217513985",
      "Name": "EnquetePlanet",
      "NameUrl": "enqueteplanet",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2012-09-04",
      "Kind": "Logo",
      "Customer": "iWave Media",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "Fill out surveys at home and earn money per completed survey. Build an extra income per month. Non-committal, no feel or time, then simply skip a survey. Give your opinion about new products and services for large enterprises. You have influence on products before they arrive in stores.",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "58289a47-e7d5-4aee-929f-5f170fe7c377",
      "Name": "De Snuiter",
      "NameUrl": "de-snuiter",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2012-01-11",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "De Snuiter is a unique gift shop where products are sold, mainly created by the clients of Odion and other institutions.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "34bdbade-e941-4047-aa9b-8c0e52746369",
      "Name": "Tandartsenpraktijk Udenhout",
      "NameUrl": "tandartsenpraktijk-udenhout",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-12-14",
      "Kind": "LogoConcept",
      "Customer": "Tandartsenpraktijk Udenhout",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "5d18aba5-b794-4347-9565-c3d59f13b4f7",
      "Name": "Begeleid Werken",
      "NameUrl": "begeleid-werken",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-10-31",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "Through Begeleid Werken, people with disabilities are escorted to and in an unpaid job. The counseling is done by one person, the job coach.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "63a1c72d-614f-472b-be02-53474d9d1596",
      "Name": "De Graslanden",
      "NameUrl": "de-graslanden",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-09-26",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "Day Care Centre De Graslanden is a location specifically aimed at day care for the elderly. The offer is an exchange between an individual and group activities program. Doing together and experience is key.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "8762d2da-4a0a-4edb-999c-33b8de6a341c",
      "Name": "Zonnehoek",
      "NameUrl": "zonnehoek",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-09-15",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "Within Zonnehoek clients in all kinds of groups can participate in activities, taking into account their capabilities and limitations. There is a separate group for people who needs lots of physical care.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "c9c58148-2c97-4eb0-9f1f-48ced289a94b",
      "Name": "Klaver Vier",
      "NameUrl": "klaver-vier",
      "ImageText": "Huisstijl Ontwerp",
      "Version": "1",
      "Date": "2011-09-15",
      "Kind": "Huisstijl",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "At Klaver Vier clients work in the gift shop or the nearby workshop where they make wonderful products.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "1",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "102fd940-ee0c-4719-8a8b-8161c996811e",
      "Name": "Garage Boss",
      "NameUrl": "garage-boss",
      "ImageText": "Vector Ontwerp",
      "Version": "1",
      "Date": "2011-07-26",
      "Kind": "Vector",
      "Customer": "Garage Boss",
      "Link": "",
      "SummaryNl": "",
      "SummaryEn": "",
      "ServicesLogo": "0",
      "MultiColorLogo": "0",
      "ServicesVector": "1",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "67bd93aa-ad56-4576-9cb1-36e0fb8d7079",
      "Name": "Standbyte",
      "NameUrl": "standbyte",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-07-26",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "At Standbyte clients can work at the computer: from typing to making designs for posters.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "75e2d216-dedb-4583-8428-7cf9ac1e409f",
      "Name": "VD84",
      "NameUrl": "vd84",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-06-08",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "Clients of Odion can work at a yard on a historic sailing ship. Clients perform various maintenance operations, make fun trips with paying passengers and provide assistance to the restoration of the historic ship.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "241368d9-ce2d-4e92-81d3-2560dee2db8e",
      "Name": "Het Watermerk",
      "NameUrl": "het-watermerk",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-04-11",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "Three groups, Industrial, Recreational and Creative, together form the watermark. Clients aged 18 and older can participate in activities. The groups are not larger than 12 people.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "ae6e5823-d2be-4b4d-99ab-bf94ec976d46",
      "Name": "De Boerderij",
      "NameUrl": "de-boerderij",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2011-04-11",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "In Middenbeemster is located De Boerderij, on De Boerderij they offer work-based activities for 20 people with intellectual and communicative restrictions at various levels. By \"farm activities\" they mean: giving care to animals, making craft products such as bread and cheese, a weaving, carpentry, a ceramics department and the care and keeping a vegetable garden.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "4a9c57a8-03b8-451b-902c-b015e8485765",
      "Name": "De Steiger",
      "NameUrl": "de-steiger",
      "ImageText": "Logo Ontwerp",
      "Version": "1",
      "Date": "2010-05-23",
      "Kind": "Logo",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "In Purmerend there is within a new housing development located the day activity center De Steiger, located near the center and public transport. Within the De Steiger clients participate in activities in groups, taking into account their capabilities and limitations.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "0",
      "ServicesEnvelop": "0",
      "ServicesBusinessCard": "0",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "e0ddb133-dc81-4b72-81c2-954cbcbf25a3",
      "Name": "Hair Creations",
      "NameUrl": "hair-creations",
      "ImageText": "Huisstijl Ontwerp",
      "Version": "1",
      "Date": "2010-04-11",
      "Kind": "Huisstijl",
      "Customer": "Hair Creations",
      "Link": "http://www.haircreations-hoensbroek.nl",
      "SummaryNl": "",
      "SummaryEn": "Hair Creations is a salon where you can enjoy all your hairstyles. They cut man, woman and child. Everyone is welcome to make an appointment. Additionally, you can also go here for more care and stylings-brand products from Kleral.",
      "ServicesLogo": "1",
      "MultiColorLogo": "0",
      "ServicesVector": "0",
      "ServicesStationery": "1",
      "ServicesEnvelop": "1",
      "ServicesBusinessCard": "1",
      "ServicesFlyer": "1",
      "ServicesStampCard": "1",
      "ServicesGiftVoucher": "1",
      "ServicesPricelist": "1",
      "ServicesStickers": "1",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "1",
      "ServicesWatermark": "0",
      "Display": "1"
    },
    {
      "Uuid": "c60750f7-01a4-45f3-94d9-bae170094889",
      "Name": "De Andere Oever",
      "NameUrl": "de-andere-oever",
      "ImageText": "Huisstijl Ontwerp",
      "Version": "1",
      "Date": "2010-03-09",
      "Kind": "Huisstijl",
      "Customer": "Stichting Odion",
      "Link": "https://www.odion.nl",
      "SummaryNl": "",
      "SummaryEn": "De Andere Oever is an apprenticeship for clients of Odion. At De Andere Oever people come to enjoy good food. Clients are helping in the kitchen and serving the guests. Also non-clients of Odion are welcome.",
      "ServicesLogo": "1",
      "MultiColorLogo": "1",
      "ServicesVector": "0",
      "ServicesStationery": "1",
      "ServicesEnvelop": "1",
      "ServicesBusinessCard": "1",
      "ServicesFlyer": "0",
      "ServicesStampCard": "0",
      "ServicesGiftVoucher": "0",
      "ServicesPricelist": "0",
      "ServicesStickers": "0",
      "ServicesFacebookCover": "0",
      "ServicesFacebookIcon": "0",
      "ServicesTwitterCover": "0",
      "ServicesTwitterIcon": "0",
      "ServicesPrint": "0",
      "ServicesWatermark": "0",
      "Display": "1"
    }
  ],
  "Illustrations": [
    {
      "Uuid": "72bbd78e-b15f-11ea-b3de-0242ac130004",
      "Kind": "Bag",
      "Name": "Vaderdag Tas #1",
      "NameUrl": "vaderdag-tas-1",
      "ImageText": "Opa is onze superheld! Bryan, Britt & Xavi",
      "Images": "1",
      "Date": "2020-06-17",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "0",
      "ServicesBoard": "0",
      "ServicesBag": "1",
      "Display": "1"
    },
    {
      "Uuid": "72bbd590-b15f-11ea-b3de-0242ac130004",
      "Kind": "Board",
      "Name": "Vaderdag Bordje #1",
      "NameUrl": "vaderdag-bordje-1",
      "ImageText": "The only thing better than having you for a dad, is my kids having you for a grandpa",
      "Images": "1",
      "Date": "2020-06-10",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "0",
      "ServicesBoard": "1",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbdec8-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Vaderdag Wegwijzer #3",
      "NameUrl": "vaderdag-wegwijzer-3",
      "ImageText": "Dit is de werkplaats van de leukste papa",
      "Images": "1",
      "Date": "2020-06-03",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbde00-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Vaderdag Wegwijzer #2",
      "NameUrl": "vaderdag-wegwijzer-2",
      "ImageText": "Mijn vader is de allerliefste ter wereld",
      "Images": "1",
      "Date": "2020-05-25",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbdcfc-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Vaderdag Wegwijzer #1",
      "NameUrl": "vaderdag-wegwijzer-1",
      "ImageText": "Voor Paul, de allerliefste vader ter wereld",
      "Images": "1",
      "Date": "2020-05-23",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbdc2a-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Tuin Wegwijzer #2",
      "NameUrl": "tuin-wegwijzer-2",
      "ImageText": "Feest, toilet, eten, drinken en spelen",
      "Images": "1",
      "Date": "2020-05-20",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbdb58-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Tuin Wegwijzer #1",
      "NameUrl": "tuin-wegwijzer-1",
      "ImageText": "Love, happy place, relax, no stress en memories",
      "Images": "1",
      "Date": "2020-05-19",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbd888-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Communie Wegwijzer #1",
      "NameUrl": "communie-wegwijzer-1",
      "ImageText": "Welkom op mijn communie, Britt, 31 mei 2020",
      "Images": "1",
      "Date": "2020-01-23",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    },
    {
      "Uuid": "72bbd95a-b15f-11ea-b3de-0242ac130004",
      "Kind": "Signpost",
      "Name": "Geboorte Wegwijzer #1",
      "NameUrl": "geboorte-wegwijzer-1",
      "ImageText": "Hoera een jongetje geboren, Xavi, 20 november 2019",
      "Images": "1",
      "Date": "2019-08-25",
      "Customer": "BBX Gifts & More",
      "ServicesSignpost": "1",
      "ServicesBoard": "0",
      "ServicesBag": "0",
      "Display": "1"
    }
  ],
  "Cards": [
    {
      "Uuid": "bb6235cc-b16f-11ea-b3de-0242ac130004",
      "Kind": "BirthdayCard",
      "Name": "Britt",
      "NameUrl": "verjaardagskaart-britt-1",
      "ImageText": "Verjaardagskaart Ontwerp",
      "Images": "2",
      "Date": "2020-06-13",
      "Customer": "François Pécasse",
      "ServicesBirthdayCard": "1",
      "ServicesBirthCard": "0",
      "ServicesInvitation": "0",
      "Display": "1"
    },
    {
      "Uuid": "bb623388-b16f-11ea-b3de-0242ac130004",
      "Kind": "BirthCard",
      "Name": "Xavi",
      "NameUrl": "geboortekaart-xavi-1",
      "ImageText": "Geboortekaart Ontwerp",
      "Images": "2",
      "Date": "2019-11-20",
      "Customer": "François Pécasse",
      "ServicesBirthdayCard": "0",
      "ServicesBirthCard": "1",
      "ServicesInvitation": "0",
      "Display": "1"
    }
  ],
  "Websites": [
    {
      "Uuid": "17",
      "Date": "2019-06-22",
      "Name": "Colors 2.0",
      "NameUrl": "colors-20",
      "ImageText": "Volledige Website",
      "Summary_nl": "",
      "Summary_en": "",
      "Link": "http://www.colors.francoispecassedesign.com",
      "Customer": "Colors 2.0",
      "CustomerUrl": "colors-20",
      "Version": "1",
      "Kind": "fullWebsite",
      "Responsive": "1",
      "Images": "1",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "1",
      "ServicesTexts": "1",
      "ServicesSeo": "1",
      "ServicesIcons": "1",
      "Display": "1"
    },
    {
      "Uuid": "16",
      "Date": "2015-04-22",
      "Name": "Start 2.0",
      "NameUrl": "start-20",
      "ImageText": "Volledige Website",
      "Summary_nl": "Start 2.0 is een website die ooit begonnen is als mijn persoonlijke startpagina. Ik wou graag zodra ik mijn browser open, dat ik mijn favorieten zag plus wat nieuws. Na een tijd is dit (al) gegroeid tot een website waarin iedereen een gratis account (zonder reclame) kan aanmaken, en zijn/haar eigen startpagina kan maken. De website is voorzien van een uw laatste nieuws berichten, agenda, taken, favorieten, tv gids voor uw favorieten series. Ook kan u de website voorzien van een eigen styling en indeling.",
      "Summary_en": "",
      "Link": "http://www.start20.nl",
      "Customer": "Start 2.0",
      "CustomerUrl": "start-20",
      "Version": "1",
      "Kind": "fullWebsite",
      "Responsive": "1",
      "Images": "6",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "1",
      "ServicesTexts": "1",
      "ServicesSeo": "1",
      "ServicesIcons": "1",
      "Display": "1"
    },
    {
      "Uuid": "15",
      "Date": "2014-03-03",
      "Name": "Sipkes Edelsmeden",
      "NameUrl": "sipkes-edelsmeden",
      "ImageText": "Website Template",
      "Summary_nl": "",
      "Summary_en": "",
      "Link": "http://www.sipkesedelsmeden.nl",
      "Customer": "Van Diemen Communicatiemakelers",
      "CustomerUrl": "",
      "Version": "1",
      "Kind": "websiteTemplate",
      "Responsive": "0",
      "Images": "2",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "0",
      "ServicesTexts": "0",
      "ServicesSeo": "0",
      "ServicesIcons": "0",
      "Display": "1"
    },
    {
      "Uuid": "11",
      "Date": "2012-03-21",
      "Name": "QIC Medewerker Portaal",
      "NameUrl": "qic-medewerker-portaal",
      "ImageText": "Website Template",
      "Summary_nl": "QIC staat voor Quality in Care, QIC beschikt over een groot aantal modules en functies die alle medewerkers in de organisatie ondersteunen bij de uitvoering van de aan hen toegewezen taken. Alle modules zijn geïntegreerd tot een systeem waarmee u de complete workflow kunt sturen en bewaken.",
      "Summary_en": "QIC stands for Quality in Care, QIC has a large number of modules and functions that assist all employees in the organization in the execution of their assigned tasks. All modules are integrated into a system that allows you to control and monitor the complete workflow.",
      "Link": "",
      "Customer": "Cormel IT Services",
      "CustomerUrl": "",
      "Version": "1",
      "Kind": "websiteTemplate",
      "Responsive": "0",
      "Images": "7",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "0",
      "ServicesTexts": "0",
      "ServicesSeo": "0",
      "ServicesIcons": "0",
      "Display": "1"
    },
    {
      "Uuid": "10",
      "Date": "2012-02-27",
      "Name": "Stichting de Zorgaanbieder",
      "NameUrl": "stichting-de-zorgaanbieder",
      "ImageText": "Website Template",
      "Summary_nl": "Stichting de Zorgaanbieder is de aanbieder van woon-, welzijns- en zorgdiensten voor ouderen en chronisch zieken. Ze hebben diverse verpleeghuizen, zorgcentra en woningen voor ouderen. Met 12 locaties bieden ze diverse diensten op maat aan.",
      "Summary_en": "Stichting de Zorgaanbieder is the provider of housing, welfare and care services for the elderly and chronically ill. They have several nursing homes, health centers and homes for the elderly. With 12 locations they offer various customized services.",
      "Link": "",
      "Customer": "Cormel IT Services",
      "CustomerUrl": "",
      "Version": "1",
      "Kind": "websiteTemplate",
      "Responsive": "0",
      "Images": "5",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "0",
      "ServicesTexts": "0",
      "ServicesSeo": "0",
      "ServicesIcons": "0",
      "Display": "1"
    },
    {
      "Uuid": "1",
      "Date": "2011-08-02",
      "Name": "Hair Creations",
      "NameUrl": "hair-creations",
      "ImageText": "Volledige Website",
      "Summary_nl": "Hair Creations is een kapsalon waar u terecht kunt voor al uw kapsels. Ze knippen man, vrouw en kind. Iedereen is dus welkom om een afspraak te maken. Daarnaast kunt u hier ook terecht voor meerdere verzorgings- en stylings- producten van het merk Kleral.",
      "Summary_en": "Hair Creations is a salon where you can enjoy all your hairstyles. They cut man, woman and child.\nEveryone is welcome to make an appointment. Additionally, you can also go here for more care and stylings-brand products from Kleral.",
      "Link": "http://www.haircreations-hoensbroek.nl",
      "Customer": "Hair Creations",
      "CustomerUrl": "",
      "Version": "1",
      "Kind": "fullWebsite",
      "Responsive": "0",
      "Images": "4",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "1",
      "ServicesTexts": "1",
      "ServicesSeo": "1",
      "ServicesIcons": "0",
      "Display": "1"
    },
    {
      "Uuid": "18",
      "Date": "2011-06-06",
      "Name": "Garage Boss",
      "NameUrl": "garage-boss",
      "ImageText": "Website Template",
      "Summary_nl": "",
      "Summary_en": "",
      "Link": "http://www.garageboss.nl",
      "Customer": "Garage Boss",
      "CustomerUrl": "garage-boss",
      "Version": "1",
      "Kind": "websiteTemplate",
      "Responsive": "0",
      "Images": "1",
      "ServicesWebdesign": "1",
      "ServicesHtmlCss": "1",
      "ServicesCms": "0",
      "ServicesTexts": "0",
      "ServicesSeo": "0",
      "ServicesIcons": "0",
      "Display": "1"
    }
  ],
  "Infographics": [

  ]
}
