import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Grid, Icon, Table, Popup} from 'semantic-ui-react'
import PageTitle from "../layout/components/PageTitle";
import {Link} from "react-router-dom";
import ContactForm from './components/ContactForm'
import { socialMedia } from '../../constants/socialMedia'
import BBXDivider from '../layout/components/BBXDivider'
import BBXSEO from '../layout/components/BBXSEO'

const Contact: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <BBXSEO
        titleTemplate={t('main.contact')}
        description={t('seo.contactDescription')}
      />
      <PageTitle name={t('main.contact')}/>

      <Grid columns={2} stackable>
        <Grid.Column width={11}>
          {t('contact.mainText')}<br/>
          {t('contact.mainText2')}
          <ContactForm />
        </Grid.Column>
        <Grid.Column width={5}>
          <Table collapsing compact className='project-info-table' stackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='top'>
                  <b>{t(`contact.address`)}</b>
                </Table.Cell>
                <Table.Cell>
                  Laurierstraat 22<br/>
                  6413RP Heerlen
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell verticalAlign='top'>
                  <b>{t(`contact.phoneNumber`)}</b>
                </Table.Cell>
                <Table.Cell>
                  06-23390233
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell verticalAlign='top'>
                  <b>{t(`contact.kvk`)}</b>
                </Table.Cell>
                <Table.Cell>
                  52089320
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <BBXDivider />

          <Table collapsing compact className='project-info-table'>
            <Table.Body>
              <Table.Row>
                <Table.Cell verticalAlign='middle'>
                  <b>{t(`contact.followUs`)}</b>
                </Table.Cell>
                <Table.Cell>
                  {
                    socialMedia.map((item, key) => {
                      return (
                        <Popup
                          inverted
                          key={key}
                          trigger={
                            <Link
                              to={item.link}
                              target='_blank'
                              className='social-media-links'
                            >
                              <Icon className={item.icon}/>
                            </Link>
                          }
                          content={item.name}
                          position='top center'
                        />
                      )
                    })
                  }

                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Contact
