import React from 'react'
import {Divider, Icon} from "semantic-ui-react";

type OwnProps = {
  section?: boolean
}

const BBXDivider: React.FC<OwnProps> = (props) => {
  return (
    <Divider horizontal section={props.section}>
      <Icon className='icon-turtle' style={{ color: '#dbdcdc' }} />
    </Divider>
  )
}

export default BBXDivider
