import React from 'react'
import Cookies from 'universal-cookie'
import i18n from '../../../i18n'
// @ts-ignore
import FlagNL from '!svg-react-loader?name=FlagNL!assets/svg/flag-nl.svg'
// @ts-ignore
import FlagEN from '!svg-react-loader?name=FlagEN!assets/svg/flag-en.svg'
// @ts-ignore
import FlagDE from '!svg-react-loader?name=FlagDE!assets/svg/flag-de.svg'

const ChangeLanguage: React.FC = () => {
  const cookies = new Cookies()

  cookies.get('language') === undefined && cookies.set('language', 'nl', {path: '/', maxAge: 60 * 60 * 24 * 365})

  const toggle = (lng: string) => {
    i18n.changeLanguage(lng)
    cookies.set('language', lng, {path: '/', maxAge: 60 * 60 * 24 * 365})
  }

  return (
    <nav id='language-menu'>
      <ul className='parent-menu'>
        <li>
          <h1>
          {
            i18n.language === 'en' ? <FlagEN/>
              : i18n.language === 'nl' && <FlagNL/>
              // : i18n.language === 'de' && <FlagDE/>
          }
          </h1>
          <ul className='sub'>
            {
              i18n.language !== 'nl' &&
              <li onClick={ () => toggle('nl') }>
                <h1><FlagNL/></h1>
              </li>
            }
            {
              i18n.language !== 'en' &&
              <li onClick={() => toggle('en')}>
                <h1><FlagEN/></h1>
              </li>
            }
            {/*{*/}
            {/*  i18n.language !== 'de' &&*/}
            {/*  <li onClick={() => toggle('de')}>*/}
            {/*    <h1><FlagDE/></h1>*/}
            {/*  </li>*/}
            {/*}*/}
          </ul>
        </li>
      </ul>
    </nav>
    // <div className='flip-container change-language'>
    //   <div className='flipper'>
    //     <div className='front' onClick={ () => toggle(flag1.code) }>
    //       { flag1.image }
    //     </div>
    //     <div className='back' onClick={ () => toggle(flag2.code) }>
    //       { flag2.image }
    //     </div>
    //   </div>
    // </div>
  )
}

export default ChangeLanguage
