import React from 'react'
import {useTranslation} from 'react-i18next'
import {Card, Header, Image} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {IRootReducerState} from '../../../state/rootReducer'
import concat from 'lodash/concat'
import take from 'lodash/take'
import assign from 'lodash/assign'

const LatestPortfolioItems: React.FC = () => {
  const {t} = useTranslation()

  let intViewportWidth = window.innerWidth
  const numberOfCards =
    intViewportWidth > 1200
      ? 3
      : intViewportWidth > 768
      ? 2
      : 1

  const portfolio = useSelector<IRootReducerState, any>(state => state.portfolio)

  const logoAndBrandingItems = portfolio.LogoAndBranding && portfolio.LogoAndBranding.map((item: any) => assign({'CategoryUrl': 'logo-en-huisstijl'}, item))
  const websitesItems = portfolio.Websites && portfolio.Websites.map((item: any) => assign({'CategoryUrl': 'websites'}, item))
  const illustrationsItems = portfolio.Illustrations && portfolio.Illustrations.map((item: any) => assign({'CategoryUrl': 'illustraties'}, item))
  const cardsItems = portfolio.Cards && portfolio.Cards.map((item: any) => assign({'CategoryUrl': 'kaarten'}, item))

  const allItems =
    portfolio.LogoAndBranding
    && portfolio.Websites
    && portfolio.Illustrations
    && portfolio.Cards
    && concat(logoAndBrandingItems, websitesItems, illustrationsItems, cardsItems)

  allItems && allItems.sort(function compare(a, b) {
    const dateA: any = new Date(a.Date);
    const dateB: any = new Date(b.Date);
    return dateB - dateA;
  })

  const lastThree = allItems && take(allItems, 3)

  return (
    <>
      <Header as='h2'>{t('dashboard.portfolio')}</Header>
      <p>{t('dashboard.portfolioText')}</p>
      <Card.Group itemsPerRow={numberOfCards}>
        {
          lastThree && lastThree.map((item: any, key: number) => {
            return (
              <Card
                key={key}
                className='portfolio-thumbnail'
                href={`/portfolio/${item.CategoryUrl}/${item.NameUrl}`}
              >
                <div className='portfolio-image'>
                  <div className='hover-image'/>
                  <Image
                    src={require(`../../../assets/images/${item.CategoryUrl}/thumbnails/${item.NameUrl}.jpg`)}
                    alt={`${item.Name} - ${item.ImageText}`}
                    className='main-image'
                  />
                </div>
                <Card.Content>
                  <Card.Header>
                    {item.Name}
                  </Card.Header>
                  <Card.Meta>
                    {t(`portfolio.${item.Kind}`)}
                  </Card.Meta>
                </Card.Content>
              </Card>
            )
          })}
      </Card.Group>
    </>
  )
}

export default LatestPortfolioItems
