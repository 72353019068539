import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/Application'

import 'assets/styles/main.less'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
