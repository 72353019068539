import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid} from 'semantic-ui-react'
// @ts-ignore
import BrandingDesignImage from '-!svg-react-loader?name=BrandingDesignImage!../../../../../assets/svg/services/branding-design.svg'

const LogoDesign: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Grid columns={2} stackable>
      <Grid.Column width={7}>
        <div className='services-description-bg'>
          <div className='services-description-radial'>
            <BrandingDesignImage id='services-branding-design-image'/>
          </div>
        </div>
      </Grid.Column>
      <Grid.Column width={9}>
        <b>{t('services.brandingDesignDescription2Title')}</b>
        <p>{t('services.brandingDesignDescription2Text')}</p>
        <br/>
        <b>{t('services.brandingDesignDescription3Title')}</b>
        <p>{t('services.brandingDesignDescription3Text')}</p>
        <br/>
        <b>{t('services.brandingDesignDescription4Title')}</b>
        <ul>
          <li>{t('services.brandingDesignDescription4Text1')}</li>
          <li>{t('services.brandingDesignDescription4Text2')}</li>
          <li>{t('services.brandingDesignDescription4Text3')}</li>
        </ul>
        <br/>
        <b>{t('services.brandingDesignDescription5Title')}</b>
        <ul>
          <li>{t('services.brandingDesignDescription5Text1')}</li>
          <li>{t('services.brandingDesignDescription5Text2')}</li>
          <li>{t('services.brandingDesignDescription5Text3')}</li>
          <li>{t('services.brandingDesignDescription5Text4')}</li>
        </ul>
        <br/>
        <b>{t('services.brandingDesignDescription6Title')}</b>
        <p>{t('services.brandingDesignDescription6Text')}</p>

      </Grid.Column>
    </Grid>
  )
}

export default LogoDesign
