import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Cookies from 'universal-cookie'

import translationEN from '../locales/en-GB.json'
import translationNL from '../locales/nl-NL.json'
import translationDE from '../locales/de-DE.json'

const cookies = new Cookies()
const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  de: {
    translation: translationDE
  }
}

i18n
  .use(LngDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    resources,
    lng: cookies.get('language') ? cookies.get('language') : 'nl',
    fallbackLng: 'nl',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  })

export default i18n
