export const colors = [
  {hex: '990000', rgb: '153, 0, 0'},
  {hex: 'CA0505', rgb: '202, 5, 5'},
  {hex: 'FB0B0B', rgb: '251, 11, 11'},
  {hex: 'FA380D', rgb: '250, 56, 13'},
  {hex: 'F96610', rgb: '249, 102, 16'},
  {hex: 'F87F0F', rgb: '248, 127, 15'},
  {hex: 'F7980E', rgb: '247, 152, 14'},
  {hex: 'FAB20E', rgb: '250, 178, 14'},
  {hex: 'FDCC0F', rgb: '253, 204, 15'},
  {hex: 'FAE113', rgb: '250, 225, 19'},
  {hex: 'F7F618', rgb: '247, 246, 24'},
  {hex: 'E1EE17', rgb: '225, 238, 23'},
  {hex: 'CCE717', rgb: '204, 231, 23'},
  {hex: 'B2DB18', rgb: '178, 219, 24'},
  {hex: '98CF19', rgb: '152, 207, 25'},
  {hex: '63B623', rgb: '99, 182, 35'},
  {hex: '2F9E2D', rgb: '47, 158, 45'},
  {hex: '269E4F', rgb: '38, 158, 79'},
  {hex: '1E9F72', rgb: '30, 159, 114'},
  {hex: '25A7A0', rgb: '37, 167, 160'},
  {hex: '2CB0CF', rgb: '44, 176, 207'},
  {hex: '2D8BB3', rgb: '45, 139, 179'},
  {hex: '2F6698', rgb: '47, 102, 152'},
  {hex: '1F4C7F', rgb: '31, 76, 127'},
  {hex: '103366', rgb: '16, 51, 102'},
  {hex: '4D1E83', rgb: '77, 30, 131'},
  {hex: '8A09A1', rgb: '138, 9, 161'}
]
