import React from 'react'
import SEO from 'react-seo-component'
import i18n from '../../../i18n'

type OwnProps = {
  titleTemplate: string
  description: string
}

const BBXSEO: React.FC<OwnProps> = (props) => {

  const siteLocale = i18n.language === 'nl' ? 'nl-NL'
    : i18n.language === 'en' ? 'en-GB'
      : i18n.language === 'de' ? 'de-DE'
        : ''

  return (
    // @ts-ignore
    <SEO
      title={
        props.titleTemplate
          ? `${props.titleTemplate} | BBX Design`
          : `BBX Design`
      }
      titleTemplate={``}
      titleSeparator={` `}
      description={props.description}
      siteLanguage={i18n.language}
      siteLocale={siteLocale}
    />
  )
}

export default BBXSEO
