import React from 'react'
// @ts-ignore
import LogoBBX from '!svg-react-loader?name=LogoBBX!assets/svg/logo-plain.svg'

const Logo = () => {
  return (
    <div id='logo'>
      <a href='/'>
        <LogoBBX />
      </a>
    </div>
  )
}

export default Logo
