import React, {useEffect} from 'react'
import {Container} from 'semantic-ui-react'
import Logo from './Logo'
import HeaderTop from './HeaderTop'
import HeaderMenu from './HeaderMenu'
import HeaderRulerTop from './HeaderRulerTop'
import {getPortfolioItems} from '../../portfolio/state/actions'
import {useDispatch} from 'react-redux'
// @ts-ignore
import {useHistory} from 'react-router-dom'
// @ts-ignore
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import Slide1 from './Slider/Slide1'
import Slide2 from './Slider/Slide2'
import Slide3 from './Slider/Slide3'
import Slide4 from './Slider/Slide4'
import ReactGA from 'react-ga'

const Header: React.FC = () => {
  ReactGA.initialize('UA-13073570-15')
  ReactGA.pageview(window.location.pathname)

  const dispatch = useDispatch()
  const history = useHistory()
  const pathname = window.location.pathname

  useEffect(() => {
    dispatch(getPortfolioItems())
  }, [history])

  const handleScroll = () => {
    let fromTop = document.documentElement.scrollTop

    if (fromTop >= 70) {
      // @ts-ignore
      document.getElementById('header-bar').classList.add('fixed-menu')
    } else {
      // @ts-ignore
      document.getElementById('header-bar').classList.remove('fixed-menu')
    }
  }

  window.addEventListener('scroll', handleScroll)

  let sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'header-slider',
    infinite: true,
    slideIndex: 0,
    autoplay: 3000
  }

  return (
    <div id='header' style={{height: pathname === '/' ? '800px' : '350px'}}>
      <HeaderTop/>
      <div id='header-bar'>
        <Container>
          <div id='logo-and-menu'>
            <Logo/>
            <HeaderMenu/>
          </div>
        </Container>
      </div>
      <HeaderRulerTop/>
      {
        pathname === '/' &&
        <Slider {...sliderSettings}>
          <div><Slide1 number={1}/></div>
          <div><Slide2 number={2}/></div>
          <div><Slide3 number={3}/></div>
          <div><Slide4 number={4}/></div>
        </Slider>
      }
      <svg id='header-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'>
        <polygon points='0 0,100 100,0 100'/>
      </svg>
    </div>
  )
}

export default Header
