import { Reducer, combineReducers } from 'redux'
import { createReducer } from '../../../state/redux'
import * as types from './types'

interface ILayoutColorReducerState {
  [key: string]: any
}

type LayoutColorReducerState = Readonly<ILayoutColorReducerState>

const initialState: LayoutColorReducerState = {}

interface IInitialColorsReducerState {
  [key: string]: any
}

type InitialColorsReducerState = Readonly<IInitialColorsReducerState>

type InitialColorsReducerBehavior<S> = Record<typeof types.STORE_INITIAL_COLORS, Reducer<S, any>>

const initial = createReducer<InitialColorsReducerState, InitialColorsReducerBehavior<InitialColorsReducerState>>(initialState, {
  [types.STORE_INITIAL_COLORS]: (_state, { payload }) => {
    return payload
  }
})

interface ICurrentColorsReducerState {
  [key: string]: any
}

type CurrentColorsReducerState = Readonly<ICurrentColorsReducerState>

type CurrentColorsReducerBehavior<S> = Record<typeof types.STORE_CURRENT_COLORS, Reducer<S, any>>

const current = createReducer<CurrentColorsReducerState, CurrentColorsReducerBehavior<CurrentColorsReducerState>>(initialState, {
  [types.STORE_CURRENT_COLORS]: (_state, { payload }) => {
    return payload
  }
})

const colors = combineReducers<LayoutColorReducerState>({
  initial,
  current
})

const layout = combineReducers<LayoutColorReducerState>({
  colors
})

export default layout
