import * as React from 'react'

import { FormTextArea, FormTextAreaProps } from 'semantic-ui-react'
import { SemanticFieldProps, mapErrorProps } from './SemanticField'
import { Field, WrappedFieldProps } from 'redux-form'

export type ReduxTextAreaProps = SemanticFieldProps<FormTextAreaProps>

const Component = (props: WrappedFieldProps & FormTextAreaProps) => {
  const { input, meta, label, ...rest } = props

  return <FormTextArea { ...input } { ...rest } { ...mapErrorProps(meta, label) } />
}

export const ReduxTextArea: React.FC<ReduxTextAreaProps> = props => <Field component={ Component } { ...props } />

ReduxTextArea.displayName = 'ReduxTextArea'

export default React.memo(ReduxTextArea)
