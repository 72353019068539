import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Table} from 'semantic-ui-react'
import moment from 'moment'
import BBXDivider from "../../layout/components/BBXDivider"

type OwnProps = {
    project: {
        Name: string
        NameUrl: string
        Customer: string
        Date: string
        Link: string
        Kind: string
        ServicesLogo: string
        ServicesVector: string
        ServicesStationery: string
        ServicesEnvelop: string
        ServicesBusinessCard: string
        ServicesFlyer: string
        ServicesStampCard: string
        ServicesGiftVoucher: string
        ServicesPricelist: string
        ServicesStickers: string
        ServicesFacebookCover: string
        ServicesFacebookIcon: string
        ServicesTwitterCover: string
        ServicesTwitterIcon: string
        ServicesPrint: string
        ServicesWatermark: string
        ServicesWebdesign: string
        ServicesHtmlCss: string
        ServicesCms: string
        ServicesTexts: string
        ServicesSeo: string
        ServicesIcons: string
        ServicesSignpost: string
        ServicesBoard: string
        ServicesBag: string
        ServicesBirthdayCard: string
        ServicesBirthCard: string
        ServicesInvitation: string
    },
    type: string
}

const ProjectDescription: React.FC<OwnProps> = (props) => {
    const {t} = useTranslation()
    const {
        project,
        type
    } = props
    const services = []

    // Logo & Branding
    project.ServicesLogo === '1' && project.Kind !== 'LogoConcept' && services.push(t(`services.logo`))
    project.ServicesLogo === '1' && project.Kind === 'LogoConcept' && services.push(t(`services.logoConcept`))
    project.ServicesVector === '1' && services.push(t('services.vector'))
    project.ServicesStationery === '1' && services.push(t('services.stationery'))
    project.ServicesEnvelop === '1' && services.push(t('services.envelop'))
    project.ServicesBusinessCard === '1' && services.push(t('services.businessCard'))
    project.ServicesFlyer === '1' && services.push(t('services.flyer'))
    project.ServicesStampCard === '1' && services.push(t('services.stampCard'))
    project.ServicesGiftVoucher === '1' && services.push(t('services.giftVoucher'))
    project.ServicesPricelist === '1' && services.push(t('services.pricelist'))
    project.ServicesStickers === '1' && services.push(t('services.stickers'))
    project.ServicesFacebookCover === '1' && services.push(t('services.facebookCover'))
    project.ServicesFacebookIcon === '1' && services.push(t('services.facebookIcon'))
    project.ServicesTwitterCover === '1' && services.push(t('services.twitterCover'))
    project.ServicesTwitterIcon === '1' && services.push(t('services.twitterIcon'))
    project.ServicesPrint === '1' && services.push(t('services.printedMatter'))
    project.ServicesWatermark === '1' && services.push(t('services.watermark'))

    // Websites
    project.ServicesWebdesign === '1' && services.push(t('services.webDesign'))
    project.ServicesHtmlCss === '1' && services.push(t('services.htmlCss'))
    project.ServicesCms === '1' && services.push(t('services.cms'))
    project.ServicesTexts === '1' && services.push(t('services.texts'))
    project.ServicesSeo === '1' && services.push(t('services.seo'))
    project.ServicesIcons === '1' && services.push(t('services.icons'))

    // Illustrations
    project.ServicesSignpost === '1' && services.push(t('services.signpost'))
    project.ServicesBoard === '1' && services.push(t('services.board'))
    project.ServicesBag === '1' && services.push(t('services.bag'))

    // Cards
    project.ServicesBirthdayCard === '1' && services.push(t('services.birthdayCard'))
    project.ServicesBirthCard === '1' && services.push(t('services.birthCard'))
    project.ServicesInvitation === '1' && services.push(t('services.invitation'))

    return (
        <>
            <Grid columns={2}>
                <Grid.Column width={11}>
                    {
                        type === 'logo-en-huisstijl' ? t(`projectDescription.${project.Name}`)
                            : type === 'websites' ? t(`websitesDescription.${project.NameUrl}`)
                            : type === 'illustraties' ? t(`illustrationsDescription.${project.NameUrl}`)
                                : type === 'kaarten' ? t(`cardsDescription.${project.NameUrl}`)
                                    : type === 'infographics' && t(`infoGraphicsDescription.${project.NameUrl}`)
                    }
                    <br/><br/>
                    {
                        type === 'logo-en-huisstijl' ?
                            project.ServicesVector === '1'
                                ? t('projectDescription.ExtraDescriptionVector')
                                : project.ServicesPrint === '1'
                                ? t('projectDescription.ExtraDescriptionWithPrintedMatter')
                                : t('projectDescription.ExtraDescription')
                            : ''
                    }
                    {
                        type === 'illustraties' ?
                            project.Customer === 'BBX Gifts & More'
                                ? t('illustrationsDescription.ExtraDescriptionBBXGiftsAndMore')
                                : t('illustrationsDescription.ExtraDescription')
                            : ''
                    }
                </Grid.Column>
                <Grid.Column width={5}>
                    <Table collapsing compact className='project-info-table'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell verticalAlign='top'>
                                    <b>{t(`portfolio.customer`)}</b>
                                </Table.Cell>
                                <Table.Cell>
                                    {project.Customer}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell verticalAlign='top'>
                                    <b>{t(`portfolio.services`)}</b>
                                </Table.Cell>
                                <Table.Cell>
                                    {services.join(', ')}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell verticalAlign='top'>
                                    <b>{t(`portfolio.year`)}</b>
                                </Table.Cell>
                                <Table.Cell>
                                    {moment(`${project.Date}`, 'YYYY:MM:DD').format('YYYY')}
                                </Table.Cell>
                            </Table.Row>
                            {project.Link ?
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>
                                        <b>{t(`portfolio.link`)}</b>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <a
                                            href={project.Link}
                                            target='_blank'
                                        >
                                            {t('portfolio.visit')}
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                                : <Table.Row/>
                            }
                        </Table.Body>
                    </Table>

                    <BBXDivider/>

                    <Table collapsing compact className='project-info-table'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell verticalAlign='top'>
                                    <b>{t(`portfolio.share`)}</b>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className='addthis_inline_share_toolbox'/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>

            <BBXDivider/>
        </>
    )
}

export default ProjectDescription
