import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Dashboard from './dashboard/Container'
import About from './about/Container'
import Portfolio from './portfolio/Container'
import PortfolioCategory from './portfolio/components/Category'
import LogoAndBrandingItem from './portfolio/components/LogoAndBrandingItem/Main'
import WebsitesItem from './portfolio/components/WebsitesItem/Main'
import IllustrationsItem from './portfolio/components/IllustrationsItem/Main'
import CardsItem from './portfolio/components/CardsItem/Main'
import Services from './services/components/Overview/Container'
import ServicesCategory from './services/components/Overview/Category'
import Customers from './services/components/Customers/Container'
import Quotation from './quotation/Container'
import Contact from './contact/Container'
import NotFound from './notFound/Container'

import Header from './layout/components/Header'
import Footer from './layout/components/Footer'

const Container = () => {
  return (
    <>

      <Header />
      <Switch>
        <Route exact path='/' component={ Dashboard } />
        <Route exact path='/over' component={ About } />
        <Route exact path='/portfolio' component={ Portfolio } />
        <Route exact path='/portfolio/:category' component={ PortfolioCategory } />
        <Route path='/portfolio/logo-en-huisstijl/:itemName' component={ LogoAndBrandingItem } />
        <Route path='/portfolio/websites/:itemName' component={ WebsitesItem } />
        <Route path='/portfolio/illustraties/:itemName' component={ IllustrationsItem } />
        <Route path='/portfolio/kaarten/:itemName' component={ CardsItem } />

        <Route exact path='/diensten' component={ Services } />
        <Route exact path='/diensten/overzicht' component={ Services } />
        <Route path='/diensten/overzicht/:category' component={ ServicesCategory } />
        <Route exact path='/diensten/klanten' component={ Customers } />
        <Route exact path='/offerte' component={ Quotation } />
        <Route exact path='/contact' component={ Contact } />
        <Route path='/404' component={NotFound} />
        <Redirect to='/404' />
      </Switch>
      <Footer />
    </>
  )
}

export default Container
