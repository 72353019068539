import React from 'react'
import {Image} from "semantic-ui-react";
import {useTranslation} from 'react-i18next'

type OwnProps = {
  name: string
  type: string
  imageText: string
}

const LogoExample: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <>
      {
        props.name &&
        <>
          <b>{t('services.logo')}</b>
          <br/><br/>
          <Image
            src={require(`../../../../assets/images/${props.type}/project/${props.name}.jpg`)}
            alt={props.imageText}
            className='portfolio-main-logo-image'
          />
        </>
      }
    </>
  )
}

export default LogoExample
