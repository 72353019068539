import React from 'react'
import {Button, Container} from 'semantic-ui-react'
import {useTranslation} from 'react-i18next'
import ChangeLanguage from './Language'
import LayoutColor from './LayoutColor'

const Header: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className='header-top'>
      <Container>
        <div className='header-top-left'/>
        <div className='header-top-right'>
          <p>{t('main.headerTopTextQuotation')}</p>
          <Button basic as='a' href='/offerte'>{t('main.requestQuotation')}</Button>
          <p>{t('main.headerTopTextCustomer')}</p>
          <Button basic>{t('main.login')}</Button>
          <LayoutColor />
          <ChangeLanguage/>
        </div>
      </Container>
    </div>
  )
}

export default Header
