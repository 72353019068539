import React from 'react'
// @ts-ignore
import ComputerBack from '-!svg-react-loader?name=ComputerBack!../../../../assets/svg/slides/slide3/computer-back.svg'
// @ts-ignore
import ComputerStroke from '-!svg-react-loader?name=ComputerStroke!../../../../assets/svg/slides/slide3/computer-stroke.svg'
// @ts-ignore
import ComputerScreen from '-!svg-react-loader?name=ComputerScreen!../../../../assets/svg/slides/slide3/computer-screen.svg'
// @ts-ignore
import TabletBack from '-!svg-react-loader?name=TabletBack!../../../../assets/svg/slides/slide3/tablet-back.svg'
// @ts-ignore
import TabletStroke from '-!svg-react-loader?name=TabletStroke!../../../../assets/svg/slides/slide3/tablet-stroke.svg'
// @ts-ignore
import TabletScreen from '-!svg-react-loader?name=TabletScreen!../../../../assets/svg/slides/slide3/tablet-screen.svg'
// @ts-ignore
import TabletButton from '-!svg-react-loader?name=TabletButton!../../../../assets/svg/slides/slide3/tablet-button.svg'
import {useTranslation} from 'react-i18next'
import {Button, Icon} from "semantic-ui-react"

type OwnProps = {
  number: number
}

const Slide3: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <div key={props.number} className='slider-content'>
      <div className='inner'>
        <div>
          <div className='slide3-websites'>
            <ComputerBack className='slide3-computer-back'/>
            <ComputerStroke className='slide3-computer-stroke'/>
            <ComputerScreen className='slide3-computer-screen'/>
            <TabletBack className='slide3-tablet-back'/>
            <TabletStroke className='slide3-tablet-stroke'/>
            <TabletScreen className='slide3-tablet-screen'/>
            <TabletButton className='slide3-tablet-button'/>
          </div>
        </div>
        <div>
          <h1>{t('slider.websites')}</h1>
          <p>{t('slider.websitesText')}</p>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/portfolio/websites`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.viewPortfolio')}
            </Button.Content>
            <Button.Content hidden>
              <Icon className='icon-portfolio'/>
            </Button.Content>
          </Button>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/offerte`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.requestQuotation')}
            </Button.Content>
            <Button.Content hidden>
              <Icon name='euro sign'/>
            </Button.Content>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Slide3
