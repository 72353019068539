import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid} from 'semantic-ui-react'
// @ts-ignore
import LogoDesignImage from '-!svg-react-loader?name=LogoDesignImage!../../../../../assets/svg/services/logo-design.svg'

const LogoDesign: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Grid columns={2} stackable>
      <Grid.Column width={7}>
        <div className='services-description-bg'>
          <div className='services-description-radial'>
            <LogoDesignImage id='services-logo-design-image'/>
          </div>
        </div>
      </Grid.Column>
      <Grid.Column width={9}>
        <b>{t('services.logoDesignDescription2Title')}</b>
        <p>{t('services.logoDesignDescription2Text')}</p>
        <br/>
        <b>{t('services.logoDesignDescription3Title')}</b>
        <ul>
          <li>{t('services.logoDesignDescription3Text1')}</li>
          <li>{t('services.logoDesignDescription3Text2')}</li>
          <li>{t('services.logoDesignDescription3Text3')}</li>
          <li>{t('services.logoDesignDescription3Text4')}</li>
        </ul>
      </Grid.Column>
    </Grid>
  )
}

export default LogoDesign
