import Redux from 'redux'
import { ActionTypes } from './types'
import {portfolioItems} from "../../../constants/portfolio";

interface GetPortfolioItems {
  type: ActionTypes.GET_PORTFOLIO_ITEMS
  payload: {
    data: {
      logoAndBranding: any,
      websites: any
    }
  }
}

export type Actions =
  | GetPortfolioItems

export interface State {}

export const defaultState: State = {}

export const portfolio: Redux.Reducer<State, Actions> = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PORTFOLIO_ITEMS: {
      return portfolioItems
    }
    // case ActionTypes.GET_PORTFOLIO_ITEMS_SUCCESS: {
    //   return action.payload.data
    // }
    default:
      return state
  }
}

export default portfolio
