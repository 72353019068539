import React from 'react'
import {Image} from "semantic-ui-react";
import {useTranslation} from 'react-i18next'
import times from 'lodash/times'

type OwnProps = {
  name: string
  type: string
  images: number
  imageText: string
}

const WebsiteExample: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <>
      {
        props.name &&
        <>
          <b>{t('services.website')}</b>
          <br/><br/>
          {
            times(props.images, ((key) => {
              return (
                <div
                  className='portfolio-main-website-image'
                  key={key}
                >
                  <Image
                    src={require(`../../../../assets/images/${props.type}/project/${props.name}-img-${key}.jpg`)}
                    alt={`${props.imageText} - ${t(`websitesDescription.${props.name}Image${key}`)}`}
                  />
                  <p>
                    <b>{`${t('portfolio.img')} ${key + 1}: `}</b>
                    {t(`websitesDescription.${props.name}Image${key}`)}
                  </p>
                </div>
              )
            }))
          }
        </>
      }
    </>
  )
}

export default WebsiteExample
