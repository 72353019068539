import React from 'react'
import {Container, Button, Icon} from 'semantic-ui-react'
import findIndex from 'lodash/findIndex'
import {RouteComponentProps} from 'react-router'
import {withRouter} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

type OwnProps = {
  items: string[]
  type: string
}

type Props =
  OwnProps &
  RouteComponentProps<{ itemName: string }>

const ProjectsNavigation: React.FC<Props> = (props: any) => {
  const {
    match: {
      params: {
        itemName
      }
    },
    items,
    type
  } = props

  const {t} = useTranslation()

  const index = items ? findIndex(items, {'NameUrl': itemName}) : []
  // @ts-ignore
  const previousIndex = parseInt(index, 10) - 1
  const previousItem = items ? items[previousIndex] : []
  // @ts-ignore
  const nextIndex = parseInt(index, 10) + 1
  const nextItem = items ? items[nextIndex] : []

  return (
    <div id='project-navigations'>
      <svg id='project-navigation-top' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'
           preserveAspectRatio='none'>
        <polygon points='0 100,100 100,100 0'/>
      </svg>
      <div id='project-navigation-middle'>
        <Container>
          <div id='buttons'>
            {
              previousItem &&
              <Button
                basic
                animated='vertical'
                href={`/portfolio/${type}/${previousItem.NameUrl}`}
              >
                <Button.Content visible>
                  {previousItem.Name}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name='chevron circle left'/>
                </Button.Content>
              </Button>
            }
            <Button
              basic
              size='big'
              animated='vertical'
              href={`/portfolio/${type}`}
            >
              <Button.Content visible>
                {t('portfolio.allProjects')}
              </Button.Content>
              <Button.Content hidden>
                <Icon name='list layout'/>
              </Button.Content>
            </Button>
            {
              nextItem &&
              <Button
                basic
                animated='vertical'
                href={`/portfolio/${type}/${nextItem.NameUrl}`}
              >
                <Button.Content visible>
                  {nextItem.Name}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name='chevron circle right'/>
                </Button.Content>
              </Button>
            }
          </div>
        </Container>
      </div>
      <svg id='project-navigation-bottom' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'
           preserveAspectRatio='none'>
        <polygon points='100 0,0 100,0 0'/>
      </svg>
    </div>
  )
}

export default withRouter(ProjectsNavigation)
