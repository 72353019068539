import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Grid, Icon, Header} from 'semantic-ui-react'
import BBXSEO from '../layout/components/BBXSEO'
import BBXDivider from '../layout/components/BBXDivider'
import LatestPortfolioItems from './components/LatestPortfolioItems'
import DashboardServices from './components/DashboardServices'
import DashboardCustomers from './components/DashboardCustomers'

export const dashboardItems = [
  {
    name: 'logoAndBranding',
    url: '/portfolio/logo-en-huisstijl',
    icon: 'logo-design'
  },
  {
    name: 'websites',
    url: '/portfolio/websites',
    icon: 'websites'
  },
  {
    name: 'services',
    url: '/diensten/overzicht',
    icon: 'services'
  },
  {
    name: 'quotation',
    url: '/offerte',
    icon: 'quotation'
  }
]

const Dashboard: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Container>
      <BBXSEO
        titleTemplate=''
        description={t('seo.homeDescription')}
      />
      <Grid id='dashboard-items' columns={4} stackable>
        {dashboardItems.map((item: any, key: number) => {
          return (
            <Grid.Column key={key}>
              <div className='dashboard-item'>
                <a href={item.url}>
                  <Icon className={`icon-${item.icon}`}/>
                  <Header as='h3'>{t(`dashboard.${item.name}`)}</Header>
                  <p>{t(`dashboard.${item.name}Description`)}</p>
                </a>
              </div>
            </Grid.Column>
          )
        })}
      </Grid>

      <BBXDivider section />

      <LatestPortfolioItems />

      <BBXDivider section />

      <DashboardServices />

      <BBXDivider section />

      <DashboardCustomers />
    </Container>
  )
}

export default Dashboard
