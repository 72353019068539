export const socialMedia = [
  {name: 'Facebook', icon: 'icon-facebook-rounded-corners', link: '//www.facebook.com/bbxdesign2020'},
  {name: 'Instagram', icon: 'icon-instagram-rounded-corners', link: '//www.instagram.com/bbxdesign2020'},
  {name: 'LinkedIn', icon: 'icon-linked-in-rounded-corners', link: '//www.linkedin.com/company/bbxdesign2020'}
  // {name: 'Behance', icon: 'icon-behance-rounded-corners', link: ''},
  // {name: 'Dribbble', icon: 'icon-dribbble-rounded-corners', link: ''}
]

export const francoisSocialMedia = [
  {name: 'CV', icon: 'icon-resume-rounded-corners', link: '//www.bbx-coding.nl'}
]
