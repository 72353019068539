import React from 'react'
import {Image} from "semantic-ui-react";
import {useTranslation} from 'react-i18next'
import BBXDivider from "../../../layout/components/BBXDivider";

type OwnProps = {
  name: string
  type: string
  imageText: string
}

const MultiColorLogoExample: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <>
      <BBXDivider />
      {
        props.name &&
        <>
          <b>{t('portfolio.multiColorLogo')}</b>
          <br/><br/>
          <Image
            src={require(`../../../../assets/images/${props.type}/multi-color/${props.name}-multi-color.jpg`)}
            alt={`${props.imageText} - ${t('portfolio.multiColorLogo')}`}
            className='portfolio-multi-color-logo-image'
          />
        </>
      }
    </>
  )
}

export default MultiColorLogoExample
