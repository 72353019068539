import React from 'react'
import {Header} from 'semantic-ui-react'
import {RouteComponentProps} from 'react-router'
import {Link, withRouter} from 'react-router-dom'

type OwnProps = {
  name: string
}

type Props =
  & RouteComponentProps
  & OwnProps

const PageTitle: React.FC<Props> = (props) => {
  return (
    <Link
      to={props.location.pathname}
      className='page-title'
    >
      <Header as='h1'>{props.name}</Header>
    </Link>
  )
}

export default withRouter(PageTitle)
