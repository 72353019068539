import * as React from 'react'

import { FormDropdown, StrictFormDropdownProps } from 'semantic-ui-react'
import { SemanticFieldProps, mapErrorProps } from './SemanticField'
import { Field, WrappedFieldProps } from 'redux-form'
import { Omit } from '../../../utils/helpers'

export type StrippedDropdownProps = Omit<StrictFormDropdownProps, 'onChange'> & { style?: React.CSSProperties; autoComplete?: string }

export type ReduxDropdownProps = SemanticFieldProps<StrippedDropdownProps>

const Component = (props: WrappedFieldProps & StrictFormDropdownProps) => {
  const { input, meta, label, ...rest } = props

  return (
    <FormDropdown
      selection
      { ...input }
      onFocus={ e => input.onFocus(e as any) }
      onChange={ (_e, { value }) => input.onChange(value) }
      { ...rest }
      { ...mapErrorProps(meta, label) }
    />
  )
}

export const ReduxDropdown: React.FC<ReduxDropdownProps> = props => <Field component={ Component } { ...props } />

ReduxDropdown.displayName = 'ReduxDropdown'

export default React.memo(ReduxDropdown)
