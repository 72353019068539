import React from 'react'
// @ts-ignore
import LetterLeft from '-!svg-react-loader?name=LetterLeft!../../../../assets/svg/slides/slide1/letter-left.svg'
// @ts-ignore
import LetterRight from '-!svg-react-loader?name=LetterRight!../../../../assets/svg/slides/slide1/letter-right.svg'
// @ts-ignore
import LetterStripesHorizontal from '-!svg-react-loader?name=LetterStripesHorizontal!../../../../assets/svg/slides/slide1/letter-stripes-horizontal.svg'
// @ts-ignore
import LetterStripesVertical from '-!svg-react-loader?name=LetterStripesVertical!../../../../assets/svg/slides/slide1/letter-stripes-vertical.svg'
// @ts-ignore
import StripesGuides1 from '-!svg-react-loader?name=StripesGuides1!../../../../assets/svg/slides/slide1/stripes-guides-1.svg'
// @ts-ignore
import StripesGuides2 from '-!svg-react-loader?name=StripesGuides2!../../../../assets/svg/slides/slide1/stripes-guides-2.svg'
// @ts-ignore
import StripesGuides3 from '-!svg-react-loader?name=StripesGuides3!../../../../assets/svg/slides/slide1/stripes-guides-3.svg'
// @ts-ignore
import StripesGuides4 from '-!svg-react-loader?name=StripesGuides4!../../../../assets/svg/slides/slide1/stripes-guides-4.svg'
// @ts-ignore
import Stripes from '-!svg-react-loader?name=Stripes!../../../../assets/svg/slides/slide1/stripes.svg'
// @ts-ignore
import Fill from '-!svg-react-loader?name=Fill!../../../../assets/svg/slides/slide1/fill.svg'
// @ts-ignore
import Divider from '-!svg-react-loader?name=Divider!../../../../assets/svg/slides/slide1/divider.svg'
import {useTranslation} from 'react-i18next'
import {Button, Icon} from "semantic-ui-react";

type OwnProps = {
  number: number
}

const Slide1: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <div key={props.number} className='slider-content'>
      <div className='inner'>
        <div>
          <div className='slide1-letter'>
            <LetterStripesHorizontal className='slide1-letter-stripes-horizontal'/>
            <LetterStripesVertical className='slide1-letter-stripes-vertical'/>
            <StripesGuides1 className='slide1-stripes-guides1'/>
            <StripesGuides2 className='slide1-stripes-guides2'/>
            <StripesGuides3 className='slide1-stripes-guides3'/>
            <StripesGuides4 className='slide1-stripes-guides4'/>
            <Stripes className='slide1-stripes'/>
            <Divider className='slide1-divider'/>
            <Fill className='slide1-fill'/>
            <LetterLeft className='slide1-letter-left'/>
            <LetterRight className='slide1-letter-right'/>
          </div>
        </div>
        <div>
          <h1>{t('slider.logoDesign')}</h1>
          <p>{t('slider.logoDesignText')}</p>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/portfolio/logo-en-huisstijl`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.viewPortfolio')}
            </Button.Content>
            <Button.Content hidden>
              <Icon className='icon-portfolio'/>
            </Button.Content>
          </Button>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/offerte`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.requestQuotation')}
            </Button.Content>
            <Button.Content hidden>
              <Icon name='euro sign'/>
            </Button.Content>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Slide1
