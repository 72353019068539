import React from 'react'
import {Container} from 'semantic-ui-react'
import PageTitle from '../../../layout/components/PageTitle'
import {servicesItems} from '../../../../constants/services'
import {RouteComponentProps} from "react-router"
import find from 'lodash/fp/find'
import LogoDesign from './Category/LogoDesign'
import BrandingDesign from './Category/BrandingDesign'
import ServicesNavigation from './Category/ServicesNavigation'
import {useTranslation} from 'react-i18next'

type Props =
  RouteComponentProps<{ category: string }>

const Services: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {
    match: {
      params: {
        category
      }
    }
  } = props

  const categoryItem = find({urlName: category}, servicesItems)

  return (
    <>
      <Container>
        <PageTitle name={categoryItem ? t(`services.${categoryItem.name}`) : ''}/>

        <p>{categoryItem && t(`services.${categoryItem.name}Description`)}</p>
        <br/>
        {
          categoryItem && categoryItem.urlName === 'logo-ontwerp' ? <LogoDesign/>
          : categoryItem && categoryItem.urlName === 'huisstijl-ontwerp' && <BrandingDesign/>
        }
      </Container>
      <ServicesNavigation />
    </>
  )
}

export default Services
