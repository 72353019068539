import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Icon, Header, Popup, Grid, Table} from 'semantic-ui-react'
import {servicesItems} from '../../../constants/services'
import {Link} from 'react-router-dom'
import {socialMedia} from "../../../constants/socialMedia"
// @ts-ignore
import BackToTop from 'react-back-to-top-button'
import BBXDivider from "./BBXDivider";

const Footer: React.FC = () => {
  const {t} = useTranslation()
  return (
    <>
      <div id='footer'>
        <svg id='footer-white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'>
          <polygon points='100 0,0 100,0 0'/>
        </svg>

        <Container>
          <Grid columns={2} stackable>
            <Grid.Column width={11}>
              <Header as='h3'>{t('footer.aboutHeader')}</Header>
              <p>
                {t('services.mainText')}<br/>
                {t('services.mainText21')}
                <Link to='/contact'>{t('services.mainText22')}</Link>
                {t('services.mainText23')}
              </p>
              <br/>
              <div className='footer-services-icons' style={{marginRight: '100px'}}>
                <Grid columns={4}>
                  {
                    servicesItems.map((item, key) => {
                      return (
                        <Grid.Column key={key}>
                          <Link
                            to={`/diensten/overzicht/${item.urlName}`}
                            className='footer-services-links'
                          >
                            <Icon className={`icon-${item.icon}`}/>
                            <p>{t(`services.${item.name}`)}</p>
                          </Link>
                        </Grid.Column>
                      )
                    })
                  }
                </Grid>
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header as='h3'>{t('main.contact')}</Header>
              <Table collapsing compact className='footer-info-table' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell verticalAlign='top'>
                      <b>{t(`contact.address`)}</b>
                    </Table.Cell>
                    <Table.Cell>
                      Laurierstraat 22<br/>
                      6413RP Heerlen
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell verticalAlign='top'>
                      <b>{t(`contact.phoneNumber`)}</b>
                    </Table.Cell>
                    <Table.Cell>
                      06-23390233
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell verticalAlign='top'>
                      <b>{t(`contact.kvk`)}</b>
                    </Table.Cell>
                    <Table.Cell>
                      52089320
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <BBXDivider/>

              <Header as='h3'>{t('contact.followUs')}</Header>
              <div className='footer-social-media-icons'>
                {
                  socialMedia.map((item, key) => {
                    return (
                      <Popup
                        inverted
                        key={key}
                        trigger={
                          <Link
                            to={item.link}
                            target='_blank'
                            className='footer-social-media-links'
                          >
                            <Icon className={item.icon}/>
                          </Link>
                        }
                        content={item.name}
                        position='top center'
                      />
                    )
                  })
                }
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <div id='footer-bottom-bar'>
        <a href='http://www.bbx-design.nl'>BBX Design &copy; 2020</a>
      </div>
      <BackToTop
        showOnScrollUp
        showAt={20}
        speed={1500}
        easing="easeOutSine"
      >
        <Icon className='icon-chevron-thin-up'/>
      </BackToTop>
    </>
  )
}

export default Footer
