import { applyMiddleware, createStore } from 'redux'
import reducers from './rootReducer'
// @ts-ignore
import axiosMiddleware from 'redux-axios-middleware'
import axios from 'axios'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

export const client = axios.create({
  baseURL: 'http://api.bbx-design.nl/v1',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(axiosMiddleware(client))
  )
)

export default store
