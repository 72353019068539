import React from 'react'
// @ts-ignore
import {useTranslation} from 'react-i18next'
import {Button, Icon} from "semantic-ui-react"
// @ts-ignore
import BusinessCardAddress from '-!svg-react-loader?name=BusinessCardAddress!../../../../assets/svg/slides/slide2/businesscard-address.svg'
// @ts-ignore
import BusinessCardBack from '-!svg-react-loader?name=BusinessCardBack!../../../../assets/svg/slides/slide2/businesscard-back.svg'
// @ts-ignore
import BusinessCardStroke from '-!svg-react-loader?name=BusinessCardStroke!../../../../assets/svg/slides/slide2/businesscard-stroke.svg'
// @ts-ignore
import EnvelopAddress from '-!svg-react-loader?name=EnvelopAddress!../../../../assets/svg/slides/slide2/envelop-address.svg'
// @ts-ignore
import EnvelopBack from '-!svg-react-loader?name=EnvelopBack!../../../../assets/svg/slides/slide2/envelop-back.svg'
// @ts-ignore
import EnvelopStroke from '-!svg-react-loader?name=EnvelopStroke!../../../../assets/svg/slides/slide2/envelop-stroke.svg'
// @ts-ignore
import PenBack from '-!svg-react-loader?name=PenBack!../../../../assets/svg/slides/slide2/pen-back.svg'
// @ts-ignore
import PenStroke from '-!svg-react-loader?name=PenStroke!../../../../assets/svg/slides/slide2/pen-stroke.svg'
// @ts-ignore
import StationeryAddress from '-!svg-react-loader?name=StationeryAddress!../../../../assets/svg/slides/slide2/stationery-address.svg'
// @ts-ignore
import StationeryBack from '-!svg-react-loader?name=StationeryBack!../../../../assets/svg/slides/slide2/stationery-back.svg'
// @ts-ignore
import StationeryCrease from '-!svg-react-loader?name=StationeryCrease!../../../../assets/svg/slides/slide2/stationery-crease.svg'
// @ts-ignore
import StationeryCreaseStroke from '-!svg-react-loader?name=StationeryCreaseStroke!../../../../assets/svg/slides/slide2/stationery-crease-stroke.svg'
// @ts-ignore
import StationeryStroke from '-!svg-react-loader?name=StationeryStroke!../../../../assets/svg/slides/slide2/stationery-stroke.svg'


type OwnProps = {
  number: number
}

const Slide2: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <div key={props.number} className='slider-content'>
      <div className='inner'>
        <div>
          <div className='slide2-branding'>
            <BusinessCardAddress className='slide2-businesscard-address'/>
            <BusinessCardBack className='slide2-businesscard-back'/>
            <BusinessCardStroke className='slide2-businesscard-stroke'/>
            <EnvelopAddress className='slide2-envelop-address'/>
            <EnvelopBack className='slide2-envelop-back'/>
            <EnvelopStroke className='slide2-envelop-stroke'/>
            <PenBack className='slide2-pen-back'/>
            <PenStroke className='slide2-pen-stroke'/>
            <StationeryAddress className='slide2-stationery-address'/>
            <StationeryBack className='slide2-stationery-back'/>
            <StationeryCrease className='slide2-stationery-crease'/>
            <StationeryCreaseStroke className='slide2-stationery-crease-stroke'/>
            <StationeryStroke className='slide2-stationery-stroke'/>
          </div>
        </div>
        <div>
          <h1>{t('slider.brandingDesign')}</h1>
          <p>{t('slider.brandingDesignText')}</p>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/portfolio/logo-en-huisstijl`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.viewPortfolio')}
            </Button.Content>
            <Button.Content hidden>
              <Icon className='icon-portfolio'/>
            </Button.Content>
          </Button>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/offerte`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.requestQuotation')}
            </Button.Content>
            <Button.Content hidden>
              <Icon name='euro sign'/>
            </Button.Content>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Slide2
