import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, Grid} from 'semantic-ui-react'
import PageTitle from "../layout/components/PageTitle";
import QuotationForm from './components/QuotationForm'
import BBXSEO from "../layout/components/BBXSEO";

const Quotation: React.FC = () => {
  const {t} = useTranslation()

  return (
    <Container>
      <BBXSEO
        titleTemplate={t('main.quotation')}
        description={t('seo.quotationDescription')}
      />
      <PageTitle name={t('main.quotation')}/>
      <Grid columns={1}>
        <Grid.Column>
          {t('quotation.mainText')}<br/>
          {t('quotation.mainText2')}
          <QuotationForm />
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Quotation
