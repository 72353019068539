import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container} from 'semantic-ui-react'
import PageTitle from '../layout/components/PageTitle'
import {Link} from 'react-router-dom'
// import BBXDivider from '../layout/components/BBXDivider'
// import {francoisSocialMedia} from '../../constants/socialMedia'
import BBXSEO from "../layout/components/BBXSEO";

const About: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Container>
      <BBXSEO
        titleTemplate={t('main.about')}
        description={t('seo.aboutDescription')}
      />
      <PageTitle name={t('main.about')}/>
      <p><b>{t('about.mainTitle')}</b></p>
      <p>{t('about.mainText1')}</p>
      <p>{t('about.mainText2')}</p>
      <p>{t('about.mainText3')}</p>
      <p>{t('about.mainText4')}</p>
      <p>{t('about.mainText5')}</p>
      <p>
        {t('about.mainText61')}
        <Link to='/contact'>{t('about.mainText62')}</Link>
        {t('about.mainText63')}
      </p>

      {/*<BBXDivider section />*/}

      {/*<Grid columns={2} stackable>*/}
      {/*  <Grid.Column>*/}
      {/*    <Item.Group>*/}
      {/*      <Item>*/}
      {/*        <Item.Image size='small' circular src='https://react.semantic-ui.com/images/wireframe/image.png'/>*/}
      {/*        <Item.Content>*/}
      {/*          <Item.Header>François</Item.Header>*/}
      {/*          <Item.Meta>{t('about.francoisFunction')}</Item.Meta>*/}
      {/*          <Item.Description>*/}
      {/*            <div className='about-social-media-icons'>*/}
      {/*              {*/}
      {/*                francoisSocialMedia.map((item, key) => {*/}
      {/*                  return (*/}
      {/*                    <Popup*/}
      {/*                      inverted*/}
      {/*                      key={key}*/}
      {/*                      trigger={*/}
      {/*                        <Link*/}
      {/*                          to={item.link}*/}
      {/*                          target='_blank'*/}
      {/*                          className='about-social-media-links'*/}
      {/*                        >*/}
      {/*                          <Icon className={item.icon}/>*/}
      {/*                        </Link>*/}
      {/*                      }*/}
      {/*                      content={item.name}*/}
      {/*                      position='top center'*/}
      {/*                    />*/}
      {/*                  )*/}
      {/*                })*/}
      {/*              }*/}
      {/*            </div>*/}
      {/*          </Item.Description>*/}
      {/*        </Item.Content>*/}
      {/*      </Item>*/}
      {/*    </Item.Group>*/}
      {/*  </Grid.Column>*/}
      {/*  <Grid.Column>*/}
      {/*    <Item.Group>*/}
      {/*      <Item>*/}
      {/*        <Item.Image size='small' circular src='https://react.semantic-ui.com/images/wireframe/image.png'/>*/}
      {/*        <Item.Content>*/}
      {/*          <Item.Header>Mariëlle</Item.Header>*/}
      {/*          <Item.Meta>{t('about.marielleFunction')}</Item.Meta>*/}
      {/*        </Item.Content>*/}
      {/*      </Item>*/}
      {/*    </Item.Group>*/}
      {/*  </Grid.Column>*/}
      {/*</Grid>*/}
    </Container>
  )
}

export default About
