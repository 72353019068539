export const servicesItems = [
  {
    name: 'logoDesign',
    urlName: 'logo-ontwerp',
    icon: 'logo-design'
  },
  {
    name: 'brandingDesign',
    urlName: 'huisstijl-ontwerp',
    icon: 'branding-design'
  },
  {
    name: 'printedMatter',
    urlName: 'drukwerk',
    icon: 'printed-matter'
  },
  {
    name: 'websites',
    urlName: 'websites-en-ontwikkeling',
    icon: 'websites'
  },
  {
    name: 'infoGraphics',
    urlName: 'infographics',
    icon: 'infographics'
  },
  {
    name: 'lettering',
    urlName: 'belettering',
    icon: 'car'
  },
  {
    name: 'brochuresLeaflets',
    urlName: 'brochures-en-folders',
    icon: 'flyer'
  }
]
