import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Form} from 'semantic-ui-react'
import Input from '../../../components/form/Input'
import TextArea from '../../../components/form/TextArea';
import {InjectedFormProps, reduxForm} from 'redux-form'
import { useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'

type Props = InjectedFormProps

const ContactForm: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {pristine, reset, submitting} = props
    // @ts-ignore
  const formValues = useSelector(state => state.form.contactForm && state.form.contactForm.values)

  const onSubmit = () => {
    // @ts-ignore
    window.emailjs.send(
      'gmail', 'template_N9STLcJo',
      {
        message_html: formValues.message,
        from_name: formValues.fullName,
        reply_to: formValues.emailAddress,
        phone_number: formValues.phoneNumber,
        company_name: formValues.companyName,
        website: formValues.website
      }
    ).then(() => {
      toastr.success('', t('contact.emailSuccessfullySent'))
    })
  }

  return (
    <Form onSubmit={onSubmit} className='contact-form'>
      <Input
        required
        placeholder={`${t('contact.fullName')} *`}
        name='fullName'
        type='text'
      />
      <Input
        placeholder={t('contact.companyName')}
        name='companyName'
        type='text'
      />
      <Input
        required
        placeholder={`${t('contact.emailAddress')} *`}
        name='emailAddress'
        type='email'
      />
      <Input
        placeholder={t('contact.website')}
        name='website'
        type='text'
      />
      <Input
        placeholder={t('contact.phoneNumber')}
        name='phoneNumber'
        type='phone'
      />
      <TextArea
        required
        placeholder={`${t('contact.message')} *`}
        name='message'
        rows={12}
      />

      <Button type="submit" disabled={pristine || submitting}>{t('contact.send')}</Button>
      <Button type="button" disabled={pristine || submitting} onClick={reset}>{t('contact.reset')}</Button>
    </Form>
  )
}

export default reduxForm({
  form: 'contactForm'
})(ContactForm)
