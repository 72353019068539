import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container} from 'semantic-ui-react'
import {Card, Image} from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import {IRootReducerState} from '../../../state/rootReducer'
import PageTitle from '../../layout/components/PageTitle'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import BBXSEO from "../../layout/components/BBXSEO";

type Props =
  RouteComponentProps<{ category: string }>

const Category: React.FC<Props> = (props: any) => {
  const {t} = useTranslation()
  const {
    match: {
      params: {
        category
      }
    }
  } = props

  let intViewportWidth = window.innerWidth
  const numberOfCards =
    intViewportWidth > 1200
      ? 3
      : intViewportWidth > 768
      ? 2
      : 1

  const categoryItems =
    category === 'logo-en-huisstijl' ? useSelector<IRootReducerState, any>(state => state.portfolio.LogoAndBranding)
      : category === 'illustraties' ? useSelector<IRootReducerState, any>(state => state.portfolio.Illustrations)
      : category === 'kaarten' ? useSelector<IRootReducerState, any>(state => state.portfolio.Cards)
        : category === 'websites' ? useSelector<IRootReducerState, any>(state => state.portfolio.Websites)
          : category === 'infographics' && useSelector<IRootReducerState, any>(state => state.portfolio.Infographics)

  const pageTitle =
    category === 'logo-en-huisstijl' ? t('main.logoAndBranding')
      : category === 'illustraties' ? t('main.illustrations')
      : category === 'kaarten' ? t('main.cards')
        : category === 'websites' ? t('main.websites')
          : category === 'infographics' ? t('main.infographics')
            : ''

  const pageDescription =
    category === 'logo-en-huisstijl' ? t('seo.portfolioLogoAndBrandingDescription')
      : category === 'illustraties' ? t('seo.portfolioIllustrationsDescription')
      : category === 'kaarten' ? t('seo.portfolioCardsDescription')
        : category === 'websites' ? t('seo.portfolioWebsitesDescription')
          : category === 'infographics' ? t('seo.portfolioInfographicsDescription')
            : ''

  return (
    <Container>
      <BBXSEO
        titleTemplate={`${pageTitle} | ${t('main.portfolio')}`}
        description={pageDescription}
      />
      <PageTitle name={pageTitle}/>
      <Card.Group itemsPerRow={numberOfCards}>
        {categoryItems && categoryItems.map((item: any, key: number) => {
          return (
            <Card
              key={key}
              className='portfolio-thumbnail'
              href={`/portfolio/${category}/${item.NameUrl}`}
            >
              <div className='portfolio-image'>
                <div className='hover-image'/>
                <Image
                  src={require(`../../../assets/images/${category}/thumbnails/${item.NameUrl}.jpg`)}
                  alt={`${item.Name} - ${item.ImageText}`}
                  className='main-image'
                />
              </div>
              <Card.Content>
                <Card.Header>
                  {item.Name}
                </Card.Header>
                <Card.Meta>
                  {t(`portfolio.${item.Kind}`)}
                  <b className='item-number'>#{categoryItems.length - key}</b>
                </Card.Meta>
              </Card.Content>
            </Card>
          )
        })}
      </Card.Group>
    </Container>
  )
}

export default withRouter(Category)
