import React from 'react'
// @ts-ignore
import PageBack from '-!svg-react-loader?name=PageBack!../../../../assets/svg/slides/slide4/page-back.svg'
// @ts-ignore
import PageStroke from '-!svg-react-loader?name=PageStroke!../../../../assets/svg/slides/slide4/page-stroke.svg'
// @ts-ignore
import CircleLargePartBack from '-!svg-react-loader?name=CircleLargePartBack!../../../../assets/svg/slides/slide4/circle-large-part-back.svg'
// @ts-ignore
import CircleLargePartStroke from '-!svg-react-loader?name=CircleLargePartStroke!../../../../assets/svg/slides/slide4/circle-large-part-stroke.svg'
// @ts-ignore
import CircleSmallPartBack from '-!svg-react-loader?name=CircleSmallPartBack!../../../../assets/svg/slides/slide4/circle-small-part-back.svg'
// @ts-ignore
import CircleSmallPartStroke from '-!svg-react-loader?name=CircleSmallPartStroke!../../../../assets/svg/slides/slide4/circle-small-part-stroke.svg'
// @ts-ignore
import DotsAndLines from '-!svg-react-loader?name=DotsAndLines!../../../../assets/svg/slides/slide4/dots-and-lines.svg'
// @ts-ignore
import FullLine from '-!svg-react-loader?name=FullLine!../../../../assets/svg/slides/slide4/full-line.svg'
// @ts-ignore
import SmallLine from '-!svg-react-loader?name=SmallLine!../../../../assets/svg/slides/slide4/small-line.svg'
// @ts-ignore
import SmallLineSecondPart from '-!svg-react-loader?name=SmallLineSecondPart!../../../../assets/svg/slides/slide4/small-line-second-part.svg'
import {useTranslation} from 'react-i18next'
import {Button, Icon} from "semantic-ui-react"

type OwnProps = {
  number: number
}

const Slide4: React.FC<OwnProps> = (props) => {
  const {t} = useTranslation()
  return (
    <div key={props.number} className='slider-content'>
      <div className='inner'>
        <div>
          <div className='slide4-infographics'>
            <PageBack className='slide4-page-back'/>
            <PageStroke className='slide4-page-stroke'/>
            <CircleLargePartBack className='slide4-circle-large-part-back'/>
            <CircleLargePartStroke className='slide4-circle-large-part-stroke'/>
            <CircleSmallPartBack className='slide4-circle-small-part-back'/>
            <CircleSmallPartStroke className='slide4-circle-small-part-stroke'/>
            <DotsAndLines className='slide4-dots-and-lines'/>
            <FullLine className='slide4-full-line'/>
            <FullLine className='slide4-full-line2'/>
            <SmallLine className='slide4-small-line'/>
            <SmallLineSecondPart className='slide4-small-line-second-part'/>
          </div>
        </div>
        <div>
          <h1>{t('slider.infographics')}</h1>
          <p>{t('slider.infographicsText')}</p>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/portfolio/infographics`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.viewPortfolio')}
            </Button.Content>
            <Button.Content hidden>
              <Icon className='icon-portfolio'/>
            </Button.Content>
          </Button>
          <Button
            basic
            size='big'
            animated='vertical'
            href={`/offerte`}
            className='slider-content-button'
          >
            <Button.Content visible>
              {t('slider.requestQuotation')}
            </Button.Content>
            <Button.Content hidden>
              <Icon name='euro sign'/>
            </Button.Content>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Slide4
