import * as React from 'react'

export interface IProps {
  content: string
  error?: any
  touched: boolean
  className?: string
}

const style = {
  color: '#db2828'
}

const Label: React.FC<IProps> = props => {
  const { content, error, touched, className } = props

  if (!content && error === true) {
    // no content and error was provided as boolean instead of a string
    return null
  }

  if (error && touched) {
    return (
      <label className={ className }>
        { content }
        &nbsp;
        <span style={ style }>{ error }</span>
      </label>
    )
  }

  return content ? <label className={ className }>{ content }</label> : null
}

export default Label
