import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import portfolio from '../app/portfolio/state/reducers'
import layout from '../app/layout/state/reducers'

export interface IRootReducerState {
  layout: any
  form: any
  portfolio: any
  toastr: any
}

export const rootReducer = combineReducers<IRootReducerState>({
  layout,
  form,
  portfolio,
  toastr: toastrReducer
})

export default rootReducer
